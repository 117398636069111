import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import Grid from "@mui/material/Grid";

import { addCustomQuestion, getCustomQuestion } from "redux/actions/library";
import TextField from "components/form/TextField";
import ButtonIconDeleteCircle from "components/form/ButtonIconDeleteCircle";
import ButtonIconAddCircle from "components/form/ButtonIconAddCircle";

import Checkbox from "@mui/material/Checkbox";

import { uploadImage } from "redux/actions/share";
import ButtonIcon from "components/form/ButtonIcon";
import { Typography } from "@material-ui/core";
import { Stack } from "immutable";
import ButtonUploadFile from "components/form/ButtonUploadFile";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .question-from {
    .question-group {
      margin-bottom: 25px;
    }

    .question-title {
      margin-bottom: 5px;
    }
  }

  .choice_container {
    display: flex;
    margin-bottom: 5px;

    .choice_selection {
      margin-right: 5px;
    }
    .choice_field {
      margin-right: 5px;
      min-width: 372px;
    }
    .choice_btn {
    }
  }
`;

const Input = styled("input")({
  display: "none",
});

const questionTypeList = [
  { value: "essay", text: "Essay" },
  { value: "multiple-choice", text: "Multiple Choices" },
];

const initChoiceFrm = [
  {
    choice_description: "",
    choice_type: "answer",
    is_answer: false,
    answer_point: 1,
    image_url: "",
    vdo_url: "",
  },
];

const PopupCreateCustomerQuestionMultiple = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
  uid,
  userId,
}) => {
  const dispatch = useDispatch();
  const [type, setType] = useState("");

  const [choices, setChoices] = useState(initChoiceFrm);
  const [questionTime, setQuestionTime] = useState(0);
  const [questionTitle, setQuestionTitle] = useState("");
  const [urlImage, setURLImage] = useState("");

  useEffect(() => {}, []);

  const onChangeReportType = (value) => {
    setType(value);
  };

  const handleConfirm = () => {};

  const handleDeleteItem = (index) => {
    let newList = [...choices];

    if (index > 0) {
      newList.splice(index, 1);
    }
    setChoices(newList);
  };

  const handleAddItem = () => {
    setChoices([
      ...choices,
      {
        choice_description: "",
        choice_type: "answer",
        is_answer: false,
        answer_point: 1,
        image_url: "",
        vdo_url: "",
      },
    ]);
  };

  const handleCheckboxChange = (value, index) => {
    let newArr = [...choices];
    console.log(newArr);
    newArr[index].is_answer = !value;
    console.log(newArr);
    setChoices(newArr);
  };

  const handleChoiceFrmChange = (value, index) => {
    let newArr = [...choices];
    newArr[index].choice_description = value;
    setChoices(newArr);
  };

  const handleSubmit = () => {
    let choice = choices.map((it, index) => {
      return { ...it, choice_no: index++ };
    });

    let obj = {
      question_type: "multiple-choice",
      question_time: +questionTime,
      video_url: "",
      question_title: questionTitle,
      question_description: "",
      choice,
      is_shuffle_answer: true,
      is_exist: false,
      image_url: urlImage,
    };
    // console.log(urlImage);
    dispatch(addCustomQuestion(obj)).then(() => {
      dispatch(getCustomQuestion()).then(() => onClose());
    });
  };

  const handleUploadImage = (e) => {
    e.persist();
    dispatch(uploadImage(e.target.files[0])).then(({ path }) => {
      setURLImage(path);
      console.log(path);
    });
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">Select Report Type</div>
            <p>
              Question Type : <strong>Multiple Choices</strong>
            </p>
            <br />
            <div className="question-from">
              <div className="question-group">
                <div className="question-title">Title Question *</div>
                <div className="question-item">
                  <TextField
                    value={questionTitle}
                    onChange={(e) => setQuestionTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="question-group">
                <div className="question-title">
                  Title Question Image (if any)
                </div>

                <div className="question-item">
                  <ButtonUploadFile
                    defaultValue={urlImage}
                    id={`upload-file`}
                    onChange={(e) => handleUploadImage(e)}

                  />
                  {/* <TextField
                    type="file"
                    onChange={(e) => handleUploadImage(e)}
                  /> */}
                </div>
              </div>
              <div className="question-group">
                <div className="question-title">Choice</div>
                <div className="question-title">
                  (Select the correct answer, if any)
                </div>
                <div className="question-item">
                  {choices?.map((item, index) => (
                    <div className="choice_container" key={index}>
                      <div>
                        <Checkbox
                          id={`checkbox-answer-${index}`}
                          {...label}
                          onChange={(e) => {
                            handleCheckboxChange(item.is_answer, index);
                          }}
                        />
                      </div>
                      <div className="choice_field">
                        <TextField
                          placeholder="Choice"
                          value={item?.choice_description}
                          className="field_choice"
                          onChange={(e) =>
                            handleChoiceFrmChange(e.target.value, index)
                          }
                        />
                      </div>
                      <div className="choice_btn">
                        {index !== 0 && (
                          <ButtonIconDeleteCircle
                            id={`btn-remove-${index}-choice`}
                            onClick={() => handleDeleteItem(index)}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  <div>
                    <ButtonIconAddCircle onClick={() => handleAddItem()} /> Add
                    more choice
                  </div>
                </div>
              </div>
              <div className="question-group">
                <div className="question-title">Time to answer (min)*</div>
                <div className="question-item">
                  <TextField
                    value={questionTime}
                    onChange={(e) => setQuestionTime(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack onClick={handleSubmit} outlined>
            Create
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupCreateCustomerQuestionMultiple;
