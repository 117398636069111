import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import SlideAlert from "containers/SlideAlert";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Home from "pages/Home";

import MemberWelcome from "pages/member/MemberWelcome";
import MemberAll from "pages/member/MemberAll";
import MemberSagment from "pages/member/MemberSagment";
import MemberSegmentDetail from "pages/member/MemberSegmentDetail";
import MemberDetail from "pages/member/MemberDetail";

import OrderWelcome from "pages/order/OrderWelcome";
import OrderTransaction from "pages/order/OrderTransaction";
import OrderTransactionCreateQuotation from "pages/order/OrderTransactionCreateQuotation";
import OrderTransactionGenerateInvoice from "pages/order/OrderTransactionGenerateInvoice";
import OrderTransactionGenerateReceipt from "pages/order/OrderTransactionGenerateReceipt";
import OrderTransactionGenerateDeliveryNote from "pages/order/OrderTransactionGenerateDeliveryNote";
import OrderRegconsign from "pages/order/OrderRegconsign";
import OrderSummaryReport from "pages/order/OrderSummaryReport";

import WrapperQuotation from "containers/documents/WrapperQuotation";
import WrapperInvoice from "containers/documents/WrapperInvoice";
import WrapperReceipt from "containers/documents/WrapperReceipt";
import WrapperDelivery from "containers/documents/WrapperDelivery";

import ReportWelcome from "pages/report/ReportWelcome";
import ReportAll from "pages/report/ReportAll";

import CampaignAll from "pages/campaign/CampaignAll";

import ConfigurationReportWelcome from "pages/configurationReport/ConfigurationReportWelcome";
import ConfigurationReportAll from "pages/configurationReport/ConfigurationReportAll";
import ConfigurationReportCreate from "pages/configurationReport/ConfigurationReportCreate";
import ConfigurationDISC from "pages/configurationReport/ConfigurationDISC";

import ExaminerDetail from "pages/centerLibrary/ExaminerDetail";
import ExaminationAll from "pages/centerLibrary/ExaminationAll";
import ExamSeriesAll from "pages/centerLibrary/ExamSeriesAll";
import ExamSeriesCreate from "pages/centerLibrary/ExamSeriesCreate";
import ExaminationDetail from "pages/centerLibrary/ExaminationDetail";

import CampaignWelcome from "pages/campaignManagement/CampaignWelcome";

import PreAssessmentWelcome from "pages/preAssessment/PreAssessmentWelcome";
import PreAssessmentAll from "pages/preAssessment/PreAssessmentAll";

import SecureRoute from "./SecureRoute";
import {
  getMasterDataProvince,
  getMasterDataDistrict,
  getMasterDataSubDistrict,
  getMasterDataBank,
  getMasterDataNationality,
  getMasterDataSubject,
  getMasterDataJobs,
  getMasterDataJobLevels,
  getMasterDataOrganization,
} from "redux/actions/masterData";
import LibaryWelcome from "pages/centerLibrary/LibaryWelcome";
import ExaminerAll from "pages/centerLibrary/ExaminerAll";
import ExaminationImport from "pages/centerLibrary/ExaminationImport";
import AdditionalQuestionAll from "pages/centerLibrary/AdditionalQuestionAll";
import AdditionalQuestionCustom from "pages/centerLibrary/AdditionalQuestionCustom";
import ExamSubjectAll from "pages/centerLibrary/ExamSubjectAll";
import CampaignManagementAll from "pages/campaignManagement/CampaignManagementAll";
import CampaignManagementCreate from "pages/campaignManagement/CampaignManagementCreate";
import CampaignManagementEdit from "pages/campaignManagement/CampaignManagementEdit";

const Router = () => {
  const accessToken = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  useEffect(() => {
    if (accessToken) {
      dispatch(getMasterDataProvince());
      dispatch(getMasterDataDistrict());
      dispatch(getMasterDataSubDistrict());
      dispatch(getMasterDataBank());
      dispatch(getMasterDataNationality());
      dispatch(getMasterDataSubject());
      dispatch(getMasterDataJobs());
      dispatch(getMasterDataJobLevels());
      dispatch(getMasterDataOrganization());
    }
  }, [accessToken, dispatch]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/logout" component={Logout} exact />
        <SecureRoute path="/" component={Home} exact />
        <SecureRoute
          path="/quotation/:id?"
          component={WrapperQuotation}
          exact
        />
        <SecureRoute path="/invoice/:id?" component={WrapperInvoice} exact />
        <SecureRoute path="/receipt/:id?" component={WrapperReceipt} exact />
        <SecureRoute path="/delivery/:id?" component={WrapperDelivery} exact />

        <SecureRoute path="/member" component={MemberWelcome} exact />
        <SecureRoute path="/member/all" component={MemberAll} exact />
        <SecureRoute
          path="/member/detail/:member_id"
          component={MemberDetail}
          exact
        />
        <SecureRoute path="/member/segment" component={MemberSagment} exact />
        <SecureRoute path="/member/detail/:id" component={MemberDetail} exact />
        <SecureRoute
          path="/member/segment/detail/:id"
          component={MemberSegmentDetail}
          exact
        />

        <SecureRoute path="/order" component={OrderWelcome} exact />
        <SecureRoute
          path="/order/transaction"
          component={OrderTransaction}
          exact
        />
        <SecureRoute
          path="/order/transaction/create-quotation"
          component={OrderTransactionCreateQuotation}
          exact
        />
        <SecureRoute
          path="/order/transaction/generate-invoice/:id"
          component={OrderTransactionGenerateInvoice}
          exact
        />
        <SecureRoute
          path="/order/transaction/generate-receipt/:id"
          component={OrderTransactionGenerateReceipt}
          exact
        />
        <SecureRoute
          path="/order/transaction/generate-delivery-note/:id"
          component={OrderTransactionGenerateDeliveryNote}
          exact
        />
        <SecureRoute
          path="/order/regconsign"
          component={OrderRegconsign}
          exact
        />
        <SecureRoute
          path="/order/summary"
          component={OrderSummaryReport}
          exact
        />

        <SecureRoute path="/report" component={ReportWelcome} exact />
        <SecureRoute path="/report/all" component={ReportAll} exact />

        <SecureRoute path="/campaign/all" component={CampaignAll} exact />

        <SecureRoute
          path="/configuration-report"
          component={ConfigurationReportWelcome}
          exact
        />
        <SecureRoute
          path="/configuration-report/all"
          component={ConfigurationReportAll}
          exact
        />

        <SecureRoute
          path="/configuration-report/:type/:id"
          component={ConfigurationReportCreate}
          exact
        />
        <SecureRoute
          path="/configuration-report/:type"
          component={ConfigurationReportCreate}
          exact
        />
        <SecureRoute
          path="/configuration-report/disc"
          component={ConfigurationDISC}
          exact
        />

        <SecureRoute path="/library" component={LibaryWelcome} exact />
        <SecureRoute
          path="/library/examiner/all"
          component={ExaminerAll}
          exact
        />
        <SecureRoute
          path="/library/exminer/detail/:id"
          component={ExaminerDetail}
          exact
        />
        <SecureRoute
          path="/library/examination/all"
          component={ExaminationAll}
          exact
        />
        <SecureRoute
          path="/library/examination/detail/:id"
          component={ExaminationDetail}
          exact
        />
        <SecureRoute
          path="/library/examination/detail/:type/:id"
          component={ExaminationDetail}
          exact
        />
        <SecureRoute
          path="/library/examination/import"
          component={ExaminationImport}
          exact
        />
        <SecureRoute
          path="/library/examseries/all"
          component={ExamSeriesAll}
          exact
        />
        <SecureRoute
          path="/library/examseries/:type"
          component={ExamSeriesCreate}
          exact
        />
        <SecureRoute
          path="/library/examseries/:type/:id"
          component={ExamSeriesCreate}
          exact
        />
        <SecureRoute
          path="/library/question/all"
          component={AdditionalQuestionAll}
          exact
        />
        <SecureRoute
          path="/library/question/custom/:id"
          component={AdditionalQuestionCustom}
          exact
        />
        <SecureRoute
          path="/library/subject/all"
          component={ExamSubjectAll}
          exact
        />

        <SecureRoute path="/campaignMngt" component={CampaignWelcome} exact />
        <SecureRoute
          path="/campaignMngt/all"
          component={CampaignManagementAll}
          exact
        />
        <SecureRoute
          path="/campaignMngt/create"
          component={CampaignManagementCreate}
          exact
        />
        <SecureRoute
          path="/campaignMngt/edit/:id"
          component={CampaignManagementEdit}
          exact
        />
        <SecureRoute
          path="/pre-assessment"
          component={PreAssessmentWelcome}
          exact
        />
        <SecureRoute
          path="/pre-assessment/all"
          component={PreAssessmentAll}
          exact
        />
      </Switch>
      <SlideAlert />
    </BrowserRouter>
  );
};

export default Router;
