import {
  GET_EXAM_USER_LIST,
  GET_EXAM_USER_DETAIL,
  GET_EXAM_USER_DETAIL_SUBJECT_LIST,
  GET_EXAM_QUESTION_LIST,
  GET_EXAM_QUESTION_BY_ID,
  CREATE_EXAM_QUESTION,
  GET_EXAM_QUESTION_CHOICE_BY_ID,
  PATCH_EXAM_QUESTION_CHOICE_BY_ID,
  PATCH_EXAM_QUESTION_BY_ID,
  PATCH_EXAM_QUESTION_STATUS_BY_ID,
  APPROVE_EXAM_QUESTION,
  GET_EXAM_SET_LIST,
  GET_RANDOM_EXAM,
  GET_ALL_QUESTION_ACTIVE,
  GET_CLIENT_LIBRARY_LIST,
  CREATE_EXAM_SET,
  GET_EXAM_SET_BY_ID,
  PATCH_EXAM_SET,
  CLEAR_EXAM_SET,
  GET_ALL_DATA_EXAM_QUESTION_LIST,
  EXAM_IMPORT_STORAGE,
  GET_EXAM_IMPORT_STORAGE_BY_ID,
  GET_EXAM_CHOICE_IMPORT_STORAGE_BY_ID,
  UPDATE_EXAM_CHOICE_IMPORT_STORAGE_BY_ID,
  MIGRATE_EXAM_IMPORT,
  CLEAR_EXAM_IMPORT_STORAGE,
  DELETE_EXAM_QUESTION_BY_ID,
  DELETE_EXAM_SET_BY_ID,
  CREATE_CUSTOM_QUESTION,
  GET_CUSTOM_QUESTION,
  GET_CUSTOMER_QUESTION_BY_ID,
  PATCH_CUSTOMER_QUESTION_BY_ID,
  GET_SUBJECT_LIST,
  GET_SUBJECT_BY_ID,
  CREATE_SUBJECT,
  PATCH_SUBJECT,
  DELETE_SUBJECT,
} from "redux/actionTypes";
import {} from "utils/common";

const mapExamUserItems = (v) => ({
  id: v.id,
  userUid: v.userUid,
  firstName: v.first_name,
  lastName: v.last_name,
  email: v.email,
  examUserType: v.exam_user_type,
  dateExam: v.date_exam,
});

const mapExamQuestionItems = (v) => ({
  id: v.id,
  code: v.code,
  name: v.name,
  nameUpper: v.name_upper,
  topicName: v.topic_name,
  level: v.level,
  jobPositionId: v.job_position_id,
  nameTH: v.name_th,
  nameEN: v.name_en,
  examOwner: v.exam_owner,
  status: v.status,
  createdAt: v.created_at,
  question: v.question,
});

const mapExamSetItems = (v) => ({
  id: v.id,
  code: v.code,
  name: v.name,
  amountQuestion: v.amount_question,
  author: v.author,
  created_at: v.createdAt,
});

const mapRandomExamItems = (v) => ({
  id: v.id,
  code: v.code,
  subjectId: v.subject_id,
  name: v.name,
  nameUpper: v.name_upper,
  topicName: v.topic_name,
  level: v.level,
  jobPositionId: v.job_position_id,
  nameTH: v.name_th,
  nameEN: v.name_en,
  examOwner: v.exam_owner,
  status: v.status,
  createdAt: v.created_at,
  question: v.question,
  question_time: 0,
  // mylevel: "",
});

const mapClientLibraryItems = (v) => ({
  // id: v.id,
  // code: v.code,
  // subjectId: v.subject_id,
  // name: v.name,
  // nameUpper: v.name_upper,
  // topicName: v.topic_name,
  // level: v.level,
  // jobPositionId: v.job_position_id,
  // nameTH: v.name_th,
  // nameEN: v.name_en,
  // examOwner: v.exam_owner,
  // status: v.status,
  // createdAt: v.created_at,
  // question: v.question,
  // time: "",
  // mylevel: "",
});

const mapCustomQuestionItems = (v) => ({
  id: v.id,
  questionTitle: v.question_title,
  questionType: v.question_type,
  answerType: v.answer_type,
  questionTime: v.question_time,
  videoUrl: v.video_url,
  imageUrl: v.image_url,
  questionDescription: v.question_description,
  isShuffleAnswer: v.is_shuffle_answer,
  status: v.status,
  companyId: v.company_id,
  isPublished: v.is_published,
  createdAt: v.created_at,
  updatedAt: v.updated_at,
});

const mapSubjectItems = (v) => ({
  id: v.id,
  code: v.code,
  name: v.name,
  status: v.status,
  createdAt: v.created_at,
});

const initialState = {
  total: 0,
  limit: 10,
  skip: 0,
  items: [],
  examUserDetail: {},
  subjectDetailList: {},
  examQuestionDetail: {},
  examQuestionChoiceDetail: {},
  randomExams: [],
  questions: [],
  clientLibraries: [],
  examSetDetail: {},
  allQuestions: [],
  examImportStorage: [],
  customQuestions: [],
  customQuestionDetail: {},
  subjectDetail: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EXAM_USER_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_EXAM_USER_LIST.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: data.map(mapExamUserItems),
      };
    }
    case GET_EXAM_USER_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_EXAM_USER_DETAIL.SUCCESS: {
      return {
        ...state,
        examUserDetail: action.payload,
      };
    }
    case GET_EXAM_USER_DETAIL_SUBJECT_LIST.SUCCESS: {
      return {
        ...state,
        subjectDetailList: action.payload,
      };
    }
    case GET_EXAM_QUESTION_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_EXAM_QUESTION_LIST.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: data.map(mapExamQuestionItems),
      };
    }
    case GET_EXAM_QUESTION_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_EXAM_QUESTION_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_EXAM_QUESTION_BY_ID.SUCCESS: {
      return {
        ...state,
        examQuestionDetail: action.payload,
      };
    }
    case GET_EXAM_QUESTION_BY_ID.FAILURE:
      return {
        ...state,
      };
    case GET_EXAM_QUESTION_CHOICE_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_EXAM_QUESTION_CHOICE_BY_ID.SUCCESS: {
      return {
        ...state,
        examQuestionChoiceDetail: action.payload,
      };
    }
    case GET_EXAM_QUESTION_CHOICE_BY_ID.FAILURE:
      return {
        ...state,
      };
    case CREATE_EXAM_QUESTION.REQUEST:
      return {
        ...state,
      };
    case CREATE_EXAM_QUESTION.SUCCESS: {
      return {
        ...state,
      };
    }
    case CREATE_EXAM_QUESTION.FAILURE:
      return {
        ...state,
      };
    case PATCH_EXAM_QUESTION_CHOICE_BY_ID.REQUEST:
      return {
        ...state,
      };
    case PATCH_EXAM_QUESTION_CHOICE_BY_ID.SUCCESS: {
      return {
        ...state,
      };
    }
    case PATCH_EXAM_QUESTION_CHOICE_BY_ID.FAILURE:
      return {
        ...state,
      };
    case PATCH_EXAM_QUESTION_BY_ID.REQUEST:
      return {
        ...state,
      };
    case PATCH_EXAM_QUESTION_BY_ID.SUCCESS: {
      return {
        ...state,
      };
    }
    case PATCH_EXAM_QUESTION_BY_ID.FAILURE:
      return {
        ...state,
      };
    case PATCH_EXAM_QUESTION_STATUS_BY_ID.REQUEST:
      return {
        ...state,
      };
    case PATCH_EXAM_QUESTION_STATUS_BY_ID.SUCCESS: {
      return {
        ...state,
      };
    }
    case PATCH_EXAM_QUESTION_STATUS_BY_ID.FAILURE:
      return {
        ...state,
      };
    case APPROVE_EXAM_QUESTION.REQUEST:
      return {
        ...state,
      };
    case APPROVE_EXAM_QUESTION.SUCCESS: {
      return {
        ...state,
      };
    }
    case APPROVE_EXAM_QUESTION.FAILURE:
      return {
        ...state,
      };
    case GET_EXAM_SET_LIST.SUCCESS: {
      const { data, limit, skip, total } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: data.map(mapExamSetItems),
      };
    }
    case GET_EXAM_SET_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_EXAM_SET_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_RANDOM_EXAM.SUCCESS: {
      const { data, limit, skip, total } = action.payload;
      console.log(data);

      return {
        ...state,
        total,
        limit,
        skip,
        randomExams: data.map(mapRandomExamItems),
      };
    }
    case GET_RANDOM_EXAM.FAILURE:
      return {
        ...state,
      };
    case GET_RANDOM_EXAM.REQUEST:
      return {
        ...state,
      };
    case GET_ALL_QUESTION_ACTIVE.FAILURE:
      return {
        ...state,
      };
    case GET_ALL_QUESTION_ACTIVE.REQUEST:
      return {
        ...state,
      };
    case GET_ALL_QUESTION_ACTIVE.SUCCESS: {
      const { data, limit, skip, total } = action.payload;

      return {
        ...state,
        total,
        limit,
        skip,
        questions: data.map(mapRandomExamItems),
      };
    }
    case GET_CLIENT_LIBRARY_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_CLIENT_LIBRARY_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_CLIENT_LIBRARY_LIST.SUCCESS: {
      const { data, limit, skip, total } = action.payload;

      return {
        ...state,
        total,
        limit,
        skip,
        clientLibraries: data.map(mapClientLibraryItems),
      };
    }
    case CREATE_EXAM_SET.FAILURE:
      return {
        ...state,
      };
    case CREATE_EXAM_SET.REQUEST:
      return {
        ...state,
      };
    case CREATE_EXAM_SET.SUCCESS: {
      return {
        ...state,
      };
    }
    case GET_EXAM_SET_BY_ID.FAILURE:
      return {
        ...state,
      };
    case GET_EXAM_SET_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_EXAM_SET_BY_ID.SUCCESS: {
      return {
        ...state,
        examSetDetail: action.payload,
      };
    }
    case PATCH_EXAM_SET.FAILURE:
      return {
        ...state,
      };
    case PATCH_EXAM_SET.REQUEST:
      return {
        ...state,
      };
    case PATCH_EXAM_SET.SUCCESS: {
      return {
        ...state,
      };
    }
    case GET_ALL_DATA_EXAM_QUESTION_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_ALL_DATA_EXAM_QUESTION_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_ALL_DATA_EXAM_QUESTION_LIST.SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        allQuestions: data.map(mapExamQuestionItems),
      };
    }
    case MIGRATE_EXAM_IMPORT.FAILURE:
      return {
        ...state,
      };
    case MIGRATE_EXAM_IMPORT.REQUEST:
      return {
        ...state,
      };
    case MIGRATE_EXAM_IMPORT.SUCCESS: {
      return {
        ...state,
      };
    }
    case CLEAR_EXAM_SET:
      return {
        ...state,
        examSetDetail: {},
      };

    case EXAM_IMPORT_STORAGE:
      return {
        ...state,
        examImportStorage: action.payload,
      };
    case GET_EXAM_IMPORT_STORAGE_BY_ID:
      return {
        ...state,
        examQuestionDetail: action.payload,
      };
    case GET_EXAM_CHOICE_IMPORT_STORAGE_BY_ID:
      return {
        ...state,
        examQuestionChoiceDetail: action.payload,
      };
    case UPDATE_EXAM_CHOICE_IMPORT_STORAGE_BY_ID:
      return {
        ...state,
        examImportStorage: action.payload,
      };
    case CLEAR_EXAM_IMPORT_STORAGE:
      return {
        ...state,
        examImportStorage: [],
      };
    case DELETE_EXAM_QUESTION_BY_ID.FAILURE:
      return {
        ...state,
      };
    case DELETE_EXAM_QUESTION_BY_ID.REQUEST:
      return {
        ...state,
      };
    case DELETE_EXAM_QUESTION_BY_ID.SUCCESS: {
      return {
        ...state,
      };
    }
    case DELETE_EXAM_SET_BY_ID.FAILURE:
      return {
        ...state,
      };
    case DELETE_EXAM_SET_BY_ID.REQUEST:
      return {
        ...state,
      };
    case DELETE_EXAM_SET_BY_ID.SUCCESS: {
      return {
        ...state,
      };
    }
    case CREATE_CUSTOM_QUESTION.FAILURE:
      return {
        ...state,
      };
    case CREATE_CUSTOM_QUESTION.REQUEST:
      return {
        ...state,
      };
    case CREATE_CUSTOM_QUESTION.SUCCESS: {
      return {
        ...state,
      };
    }
    case GET_CUSTOM_QUESTION.FAILURE:
      return {
        ...state,
      };
    case GET_CUSTOM_QUESTION.REQUEST:
      return {
        ...state,
      };
    case GET_CUSTOM_QUESTION.SUCCESS: {
      const { data, limit, skip, total } = action.payload;

      return {
        ...state,
        total,
        limit,
        skip,
        items: data.map(mapCustomQuestionItems),
      };
    }
    case GET_CUSTOMER_QUESTION_BY_ID.FAILURE:
      return {
        ...state,
      };
    case GET_CUSTOMER_QUESTION_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_CUSTOMER_QUESTION_BY_ID.SUCCESS: {
      return {
        ...state,
        customQuestionDetail: action.payload,
      };
    }
    case PATCH_CUSTOMER_QUESTION_BY_ID.FAILURE:
      return {
        ...state,
      };
    case PATCH_CUSTOMER_QUESTION_BY_ID.REQUEST:
      return {
        ...state,
      };
    case PATCH_CUSTOMER_QUESTION_BY_ID.SUCCESS: {
      return {
        ...state,
      };
    }
    case GET_SUBJECT_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_SUBJECT_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_SUBJECT_LIST.SUCCESS: {
      const { data, limit, skip, total } = action.payload;

      return {
        ...state,
        total,
        limit,
        skip,
        items: data.map(mapSubjectItems),
      };
    }
    case GET_SUBJECT_BY_ID.FAILURE:
      return {
        ...state,
      };
    case GET_SUBJECT_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_SUBJECT_BY_ID.SUCCESS: {
      return {
        ...state,
        subjectDetail: action.payload,
      };
    }
    case CREATE_SUBJECT.FAILURE:
      return {
        ...state,
      };
    case CREATE_SUBJECT.REQUEST:
      return {
        ...state,
      };
    case CREATE_SUBJECT.SUCCESS: {
      return {
        ...state,
      };
    }
    case PATCH_SUBJECT.FAILURE:
      return {
        ...state,
      };
    case PATCH_SUBJECT.REQUEST:
      return {
        ...state,
      };
    case PATCH_SUBJECT.SUCCESS: {
      return {
        ...state,
      };
    }
    case DELETE_SUBJECT.FAILURE:
      return {
        ...state,
      };
    case DELETE_SUBJECT.REQUEST:
      return {
        ...state,
      };
    case DELETE_SUBJECT.SUCCESS: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
