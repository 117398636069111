import {
  ADD_CUSTOM_QUESTION_STORAGE,
  ADD_INVITE_STORAGE,
  ADD_TEST_EXAM_STORAGE,
  CLEAR_CUSTOM_QUESTION_STORAGE,
  CLEAR_TEST_EXAM_STORAGE,
  CREATE_CAMPAIGN,
  CREATE_NVITE_EXAMER,
  DELETE_CAMPAIGN_BY_ID,
  GET_CAMPAIGN_BY_ID,
  GET_CAMPAIGN_MANAGEMENT_LIST,
  GET_CUSTOM_QUESTION_LIST,
  GET_TEST_EXAM_LIST,
  PATCH_CAMPAIGN_BY_ID,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

const mapFilter = (v) => ({
  fname: v.firstName || null,
  lname: v.lastName || null,
  date_exam: v.dateExam || null,
});

export const getCampaignList =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_CAMPAIGN_MANAGEMENT_LIST.REQUEST });
    return getAPI({
      url: "cms/campaign",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_CAMPAIGN_MANAGEMENT_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_CAMPAIGN_MANAGEMENT_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getTestSetList =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_TEST_EXAM_LIST.REQUEST });
    return getAPI({
      url: "cms/searchExamSet",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_TEST_EXAM_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_TEST_EXAM_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getCustomQuestionList =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_CUSTOM_QUESTION_LIST.REQUEST });
    return getAPI({
      url: "cms/searchExamCustomQuestion",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_CUSTOM_QUESTION_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_CUSTOM_QUESTION_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const deleteCampaignById = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: DELETE_CAMPAIGN_BY_ID.REQUEST });
  return deleteAPI({
    url: `cms/campaign/${id}`,
  })
    .then((data) => dispatch({ type: DELETE_CAMPAIGN_BY_ID.SUCCESS }))
    .catch((error) => dispatch({ type: DELETE_CAMPAIGN_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const addExamsetStorage = (obj) => (dispatch, getState) => {
  const { examsetStorage } = getState().campaign;
  console.log(obj);
  dispatch(showLoading());
  // examsetStorage.push(obj);
  dispatch({ type: ADD_TEST_EXAM_STORAGE, payload: obj });
  dispatch(hideLoading());
};

export const addCustomQuestionStorage = (obj) => (dispatch, getState) => {
  const { customQuestionStorage } = getState().campaign;
  dispatch(showLoading());
  // customQuestionStorage.push(obj);
  dispatch({ type: ADD_CUSTOM_QUESTION_STORAGE, payload: obj });
  dispatch(hideLoading());
};

export const clearExamsetStorage = () => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CLEAR_TEST_EXAM_STORAGE });
  dispatch(hideLoading());
};

export const clearCustomQuestionStorage = () => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CLEAR_CUSTOM_QUESTION_STORAGE });
  dispatch(hideLoading());
};

export const createCampaign = (obj) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CREATE_CAMPAIGN.REQUEST });
  return postAPI({
    url: `cms/campaign`,
    data: obj,
  })
    .then((data) => dispatch({ type: CREATE_CAMPAIGN.SUCCESS, payload: data }))
    .catch((error) => dispatch({ type: CREATE_CAMPAIGN.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createNvite = (obj) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CREATE_NVITE_EXAMER.REQUEST });
  return postAPI({
    url: `cms/inviteExamer`,
    data: obj,
  })
    .then((data) => dispatch({ type: CREATE_NVITE_EXAMER.SUCCESS }))
    .catch((error) => dispatch({ type: CREATE_NVITE_EXAMER.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const addInviteStorage = (obj) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: ADD_INVITE_STORAGE, payload: obj });
  dispatch(hideLoading());
};

export const getCampaignById = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_CAMPAIGN_BY_ID.REQUEST });
  return getAPI({
    url: `cms/campaign/${id}`,
  })
    .then((data) =>
      dispatch({ type: GET_CAMPAIGN_BY_ID.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_CAMPAIGN_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateCampaignById = (obj, id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: PATCH_CAMPAIGN_BY_ID.REQUEST });
  return patchAPI({
    url: `cms/campaign/${id}`,
    data: obj,
  })
    .then((data) => dispatch({ type: PATCH_CAMPAIGN_BY_ID.SUCCESS }))
    .catch((error) => dispatch({ type: PATCH_CAMPAIGN_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
