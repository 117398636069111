import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";

import PopupApproveExamQuestion from "containers/popup/PopupApproveExamQuestion";
import ReactHtmlParser from "react-html-parser";

import formatValue from "utils/formatValue";

import {
  getExamQuestionList,
  getAllDataExamQuestion,
  deleteExamQuestionById,
} from "redux/actions/library";
import PopupManageExamQuestion from "containers/popup/PopupManageExamQuestion";
import { selectMasterSubject } from "../../redux/selectors";

import { Checkbox } from "@material-ui/core";
import StatusCell from "components/table/StatusCell";
import DeltaToHtml from "components/form/DeltaToHtml";
import { useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 190px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .truncate {
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    h1,
    h2,
    h3,
    h4,
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiDataGrid-columnHeader {
    &.MuiDataGrid-columnHeader--alignCenter {
      padding: 0;
    }
  }
`;

const initFilter = {
  code: "",
  examOwner: "",
  subjectId: "",
  topicName: "",
  level: "",
  status: "",
  question: "",
};

const sectionList = [{ value: null, text: "เลือกหัวข้อ" }];
const difficultyLevelList = [
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
  { value: 5, text: 5 },
  { value: 6, text: 6 },
  { value: 7, text: 7 },
  { value: 8, text: 8 },
  { value: 9, text: 9 },
  { value: 10, text: 10 },
  { value: 11, text: 11 },
];
const statusList = [{ value: null, text: "เลือกสถานะ" }];

const ExaminationAll = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const masterDataSubject = useSelector(selectMasterSubject);
  const { items, total, limit, allQuestions } = useSelector(
    (state) => state.library
  );

  const [dataSubject, setDataSubject] = useState(masterDataSubject);
  const [examQuestionObj, setExamQuestionObj] = useState();

  const [examQuestionList, setExamQuestionList] = useState(items);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [isOpenCreateExamModal, setIsOpenCreateExamModal] = useState(false);
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);

  const [selectedId, setSelectedId] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setDataSubject(masterDataSubject);
  }, [masterDataSubject]);

  useEffect(() => {
    setExamQuestionList(items);
  }, [items]);

  const LinkDetailCell = ({ value, row }) => {
    return (
      <RouterLinkCell to={`/library/examination/detail/${row?.id}`}>
        {value}
      </RouterLinkCell>
    );
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const ConvertHtmlToString = ({ value }) => {
    if (value === "") {
      return "-";
    }
    let isDeltaString = value?.startsWith("[");
    if (isDeltaString)
      return <div className="truncate">{<DeltaToHtml contents={value} />}</div>;

    return <div className="truncate">{ReactHtmlParser(value)}</div>;
  };

  const DateTimeDisplay = ({ value }) => {
    return `${formatValue("DateTime", value)}`;
  };

  const ApproveButton = ({ row }) => {
    return <Button onClick={() => handleApprove(row)}>อนุมัติ</Button>;
  };

  const handleApprove = (row) => {
    let obj = {
      questions: [
        {
          id: row?.id,
          status: "active",
        },
      ],
    };
    setExamQuestionObj(obj);
    setIsOpenApproveModal(true);
  };

  const handleConfirmChange = (value) => {
    setSelectedId((prev) => {
      let isExisted = prev.map((it) => it.id).includes(value?.id);

      let newSelected = isExisted
        ? prev.filter((it) => it.id !== value?.id)
        : [...prev, value];
      return newSelected;
    });
  };

  const handleCheckAll = () => {
    setSelectedAll(!selectedAll);
  };

  useEffect(() => {
    if (selectedAll) {
      // dispatch(getAllDataExamQuestion(initFilter));
      setSelectedId(examQuestionList);
    } else {
      setSelectedId([]);
    }
  }, [selectedAll]);

  useEffect(() => {}, [selectedId]);

  // useEffect(() => {
  //   if (selectedAll) {
  //     setSelectedId(allQuestions);
  //   }
  // }, [allQuestions]);

  const StatusCellWrapper = ({ value }) => {
    let type = "";
    if (value === "pending") {
      type = "pending";
    } else if (value === "active") {
      type = "active";
    } else if (value === "disabled") {
      type = "disabled";
    }
    return <StatusCell type={type}>{type}</StatusCell>;
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      renderCell: ({ row }) => (
        <Checkbox
          checked={selectedId.map((it) => it?.id).includes(row?.id)}
          onChange={() => handleConfirmChange(row)}
        />
      ),
      renderHeader: ({ row }) => (
        <Checkbox checked={selectedAll} onChange={() => handleCheckAll()} />
      ),
    },
    {
      field: "id",
      headerName: "ลำดับ",
      width: 100,
    },
    {
      field: "code",
      headerName: "รหัสข้อสอบ",
      renderCell: LinkDetailCell,
      width: 200,
    },
    {
      field: "question",
      headerName: "โจทย์",
      width: 500,
      renderCell: ConvertHtmlToString,
    },
    { field: "nameUpper", headerName: "หมวดวิชา", width: 200 },
    {
      field: "level",
      headerName: "ระดับความยาก",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "nameTH",
      headerName: "ตำแหน่งงาน",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "examOwner",
      headerName: "ชื่อผู้ออกข้อสอบ",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "createdAt",
      headerName: "วันที่สร้างข้อสอบ",
      width: 200,
      renderCell: DateTimeDisplay,
    },
    {
      field: "activation",
      headerName: "การเปิดใช้งาน",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "",
      headerName: "Verify",
      width: 200,
      renderCell: ApproveButton,
    },
    {
      field: "status",
      headerName: "สถานะ",
      width: 200,
      renderCell: StatusCellWrapper,
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getExamQuestionList(initFilter));
  };

  const handlePageChange = (nextPage) => {
    setSelectedAll(false);
    setPage(nextPage);
    dispatch(getExamQuestionList(filter, nextPage * limit));
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getExamQuestionList(filter));
  };

  const handleCreateExam = () => {
    setIsOpenCreateExamModal(true);
  };

  const handleCloseCreateExam = () => {
    dispatch(getExamQuestionList(filter)).then(() =>
      setIsOpenCreateExamModal(false)
    );
  };

  const handleCloseApproveExamQuestion = () => {
    setIsOpenApproveModal(false);
    handleSearch();
  };

  const handleDeleteExam = (value) => {
    dispatch(deleteExamQuestionById(value?.id)).then(() => handleSearch());
  };

  const handleSelectApproveExam = () => {
    let obj = {
      questions: selectedId.map((it) => ({ id: it.id, status: "active" })),
    };
    setExamQuestionObj(obj);
    setIsOpenApproveModal(true);
  };

  return (
    <SidebarLayout title="Examination">
      {Boolean(isOpenCreateExamModal) && (
        <PopupManageExamQuestion open onClose={() => handleCloseCreateExam()} />
      )}
      {Boolean(isOpenApproveModal) && (
        <PopupApproveExamQuestion
          open
          onClose={() => handleCloseApproveExamQuestion()}
          obj={examQuestionObj}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/library/examseries/all", text: "ชุดข้อสอบ" },
            { path: "/library/examination/all", text: "ข้อสอบ" },
            { path: "/library/examiner/all", text: "ผู้เข้าสอบ" },
            { path: "/library/question/all", text: "คำถามเพิ่มเติม" },
            { path: "/library/subject/all", text: "วิชา" },
          ]}
        />

        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="รหัสข้อสอบ"
                  value={filter.code}
                  onChange={(e) => handleFilterChange("code", e.target.value)}
                  name="search_code"
                />
                <TextField
                  label="โจทย์"
                  value={filter.question}
                  onChange={(e) =>
                    handleFilterChange("question", e.target.value)
                  }
                  name="search_question"
                />
                <TextField
                  label="ผู้ออกข้อสอบ"
                  value={filter.examOwner}
                  onChange={(e) =>
                    handleFilterChange("examOwner", e.target.value)
                  }
                  name="search_examOwner"
                />
                {/* <Select
                  label="หมวดวิชา"
                  value={filter.subjectId}
                  items={dataSubject}
                  defaultValue="หมวดวิชา"
                  className="field-subjectId"
                  onChange={(e) =>
                    handleFilterChange("subjectId", e.target.value)
                  }
                /> */}
                <Select
                  label="หัวข้อ"
                  name={"section"}
                  items={sectionList}
                  defaultValue="หัวข้อ"
                  className="field-section"
                />
              </div>
              <div className="group-field">
                <Select
                  label="ระดับความยาก"
                  value={filter.level}
                  items={difficultyLevelList}
                  defaultValue="ระดับความยาก"
                  className="field-examinerType"
                  onChange={(e) => handleFilterChange("level", e.target.value)}
                />
                <Select
                  label="สถานะ"
                  name={"status"}
                  items={statusList}
                  defaultValue="สถานะ"
                  className="field-examinerType"
                />
              </div>
            </div>
            <br />
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button
                    onClick={() => history.push("/library/examination/import")}
                  >
                    อัพข้อสอบเป็นชุด
                  </Button>
                  <Button onClick={() => handleSelectApproveExam()}>
                    อนุมัติข้อสอบ
                  </Button>
                  <Button onClick={() => handleCreateExam()}>
                    สร้างข้อสอบ
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={examQuestionList}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              onDeleteRow={handleDeleteExam}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ExaminationAll;
