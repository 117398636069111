import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { checkValue } from "utils/common";
import formatValue from "utils/formatValue";

import TextField from "components/form/TextField";
import Button from "components/form/Button";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

import { ArrowBack as BackIcon, Edit as EditIcon } from "@material-ui/icons";

import ReactHtmlParser from "react-html-parser";

import {
  saveExamImport,
  migrateExam,
  clearExamStorage,
} from "redux/actions/library";
import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";

import DeltaToHtml from "components/form/DeltaToHtml";

import * as XLSX from "xlsx";
import Table from "components/table/Table";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }
  .table-wrapper {
    button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }
  .headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    height: 35px;
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }
  .border-grid {
    border: 1px solid #000;
  }
  .fix-height {
    height: 30px;
  }
  .btn-edit {
    color: #a32330;
    border-color: #a32330;
    padding: 0 auto;
    margin: 10px 0;
    height: 40px;
    :disabled {
      opacity: 0.5;
    }
  }
  p {
    margin: 0;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 500px;
          min-width: 500px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 190px;
          height: 47px;
        }
      }
    }
  }

  .truncate {
    white-space: nowrap;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .import-container {
    margin-top: 20px;
    text-align: center;
  }

  .text-red {
    color: #a81e21;
  }
`;

const ExaminationImport = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const masterDataSubject = useSelector(selectMasterSubject);
  const masterDataJob = useSelector(selectMasterJob);
  const [dataExams, setDataExams] = useState();
  const { examImportStorage } = useSelector((state) => state.library);
  const [data, setData] = useState({
    rows: [],
    totalRows: 0,
    pageSize: 10,
    page: 0,
  });

  const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));

  useEffect(() => {}, []);

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const ConvertHtmlToString = ({ value }) => {
    if (value === "") {
      return "-";
    }
    let isDeltaString = value?.startsWith("[");
    if (isDeltaString)
      return <div className="truncate">{<DeltaToHtml contents={value} />}</div>;

    return <div className="truncate">{ReactHtmlParser(value)}</div>;
  };

  const DsipalySubjectName = ({ value }) => {
    if (!value || value === "") {
      return "-";
    }
    return `${GetSubjectName(value)}`;
  };

  const columns = [
    {
      field: "id",
      headerName: "ลำดับ",
      width: 100,
    },
    {
      field: "code",
      headerName: "รหัสข้อสอบ",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "question",
      headerName: "โจทย์",
      width: 500,
      renderCell: ConvertHtmlToString,
    },
    {
      field: "subject_id",
      headerName: "วิชา",
      width: 200,
      renderCell: DsipalySubjectName,
    },
    { field: "topic_name", headerName: "หัวข้อ", width: 200 },
    {
      field: "level",
      headerName: "ระดับความยาก",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "วันที่สร้างข้อสอบ",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "exam_owner",
      headerName: "ชื่อผู้ออกข้อสอบ",
      width: 200,
    },
    {
      field: "status",
      headerName: "สถานะข้อสอบ",
      width: 200,
    },
  ];

  const GetSubjectId = (name) => {
    const upperCased = name.toUpperCase();
    const id = masterDataSubject
      .filter((it) => it.text == upperCased)
      .map((it) => it.value)[0];
    return id;
  };

  const GetSubjectName = (id) => {
    const name = masterDataSubject
      .filter((it) => it.value == id)
      .map((it) => it.text)[0];
    return name;
  };

  const GetJobId = (name) => {
    const upperCased = name.toUpperCase();
    const id = masterDataJob
      .filter((it) => it.text === upperCased)
      .map((it) => it.value)[0];
    return id;
  };

  const GetJobName = (id) => {
    const name = masterDataJob
      .filter((it) => it.value == id)
      .map((it) => it.text)[0];
    return name;
  };

  const handleReadFile = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      var workbook = XLSX.read(evt.target.result, { type: "array" });
      var jsonExam = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
      );
      let dataExamArr = [];
      jsonExam.forEach((it, index) => {
        if (it.length == 0) return;

        if (index >= 2) {
          console.log(it);
          let dataExam = it;
          let exam_questions_choices = createExamQuestionChoice(
            it[4],
            dataExam
          );

          let is_answer = exam_questions_choices
            .filter((it) => it.is_answer)
            .map((it) => it.choice_no)[0];

          dataExamArr.push({
            id: index + 1,
            subject_id: GetSubjectId(it[0]),
            subject: it[0],
            topic_name: it[1],
            exam_owner: it[2],
            level: it[3],
            amount_answer: it[4],
            job_position_id: GetJobId(it[5]),
            is_random_answer:
              it[jsonExam[1].length - 1] === "No" ? false : true,
            question: it[6],
            exam_questions_choices,
            is_answer,
            status: "ร่าง",
          });
        }
      });

      dispatch(saveExamImport(dataExamArr));
    };
    reader.readAsArrayBuffer(file);
  };

  const createExamQuestionChoice = (numOfChoice, exam) => {
    let examChoice = [];
    let lengthOfChoice = numOfChoice * 2;
    let choiceIndexStart = 6;
    let choiceNo = 0;

    for (let index = numOfChoice; index <= lengthOfChoice; index++) {
      let choiceIndex = choiceIndexStart;
      let choiceImgIndex = choiceIndexStart + 1;

      let examDetail = {
        choice_description: exam[choiceIndex],
        choice_image: [
          {
            image_url: exam[choiceImgIndex] ? exam[choiceImgIndex] : "",
          },
        ],
        choice_no: choiceNo,
        choice_type: index === numOfChoice ? "question" : "answer",
      };

      if (choiceNo !== 0) {
        examDetail.is_answer =
          exam[numOfChoice * 2 + 8] === choiceNo ? true : false;
      }

      examChoice.push(examDetail);

      choiceNo++;
      choiceIndexStart = choiceIndexStart + 2;
    }
    return examChoice;
  };

  useEffect(() => {
    setDataExams(examImportStorage);
  }, [examImportStorage]);

  useEffect(() => {
    setTimeout(() => {
      const rows = dataExams?.slice(
        data.page * data.pageSize,
        data.page * data.pageSize + data.pageSize
      );

      updateData("rowCount", dataExams?.length);
      updateData("rows", rows);
    }, 500);
  }, [data.page, data.pageSize, dataExams]);

  const handlePageChange = (nextPage) => {
    updateData("page", nextPage);
  };

  const handleDeleteExam = () => {};
  const handleEditExam = ({ id }) => {
    console.log(id);
    history.push(`/library/examination/detail/import/${id}`);
  };

  const handleSubmitImportExam = () => {
    const import_datas = examImportStorage.map((it) => {
      let data = {
        subject: GetSubjectName(it.subject_id),
        topic_name: it.topic_name,
        exam_owner: it.exam_owner,
        level: it.level,
        amount_answer: it.amount_answer,
        job_position: GetJobName(it.job_position_id),
        is_random_answer: it.is_random_answer,
      };

      let exam_questions_choices = it.exam_questions_choices;
      let choices = [];

      for (let index = 0; index < exam_questions_choices?.length; index++) {
        console.log("exam_questions_choices", exam_questions_choices);
        if (index === 0) {
          data.question = exam_questions_choices?.[index]?.choice_description;
          data.question_image =
            exam_questions_choices?.[index]?.choice_image?.[0].image_url;
        } else {
          choices.push({
            choice_description:
              exam_questions_choices?.[index].choice_description,
            is_answer: exam_questions_choices?.[index]?.is_answer,
            images:
              exam_questions_choices?.[index]?.choice_image?.[0].image_url,
          });
        }
      }
      data.choices = choices;
      return data;
    });

    let obj = {
      import_datas: import_datas,
    };

    console.log(obj);

    dispatch(migrateExam(obj)).then(() => {
      dispatch(clearExamStorage());
      history.push("/library/examination/all");
    });
  };

  return (
    <SidebarLayout title="Examination">
      <Div>
        <SubMenu
          menu={[
            { path: "/library/examseries/all", text: "ชุดข้อสอบ" },
            { path: "/library/examination/all", text: "ข้อสอบ" },
            { path: "/library/examiner/all", text: "ผู้เข้าสอบ" },
            { path: "/library/question/all", text: "คำถามเพิ่มเติม" },
            { path: "/library/subject/all", text: "วิชา" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="headline">นำเข้าข้อสอบเป็นชุด</div>
              <p>ท่านสามารถนำเข้าข้อสอบแบบเป็นชุดได้โดยการ upload ไฟล์ CSV</p>
              <p>กรณีที่โจทย์มีรูปภาพ คุณสามารถนำเข้ารูปภาพในภายหลัง</p>
              <div className="group-field">
                <TextField type="file" onChange={handleReadFile} />
              </div>
            </div>
          </div>
          <br />
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={data?.rows}
              rowCount={dataExams?.length}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              onDeleteRow={handleDeleteExam}
              onEditRow={handleEditExam}
            />
          </div>
        </div>
        <div className="import-container">
          <p className="text-red">
            <strong>
              รหัสข้อสอบ จะถูกสร้างเมื่อยืนยันการนำเข้าข้อสอบแล้ว
              กรุณาตรวจสอบข้อมูลก่อนยืนยันการนำเข้าข้อมูล
            </strong>
          </p>
          <br />
          <Button onClick={() => handleSubmitImportExam()}>
            ยืนยันการนำเข้าข้อมูล
          </Button>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ExaminationImport;
