import {
  ADD_MEMBER,
  GET_MEMBER_BY_ID,
  GET_MEMBER_LIST,
  APPROVE_DOCUMENT_MEMBER,
  REQUEST_DOCUMENT_MEMBER,
  EDIT_PERSONAL_INFO,
  EDIT_MEMBER_INFO,
  EDIT_MEMBER_STATUS,
  ADD_SEGMENT,
  GET_SEGMENT_LIST,
  PATCH_SEGMENT,
  DELETE_SEGMENT_ITEM,
  RESEND_VERIFY_EMAIL,
  FORGET_PASSWORD,
  ADD_USER_SEGMENT,
  GET_SEGMENT_BY_ID,
  DELETE_USER_SEGMENT_ITEM,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

const mapFilter = (v) => ({
  "date_ship[$gte]": v.fromDate || null,
  "date_ship[$lte]": v.toDate || null,
  member_no: v.id || null,
  first_name: v.firstName || null,
  last_name: v.lastName || null,
  email: v.email || null,
  member_type: v.type,
  segment: v.segment || null,
});

export const addMember = (member) => (dispatch) => {
  dispatch({ type: ADD_MEMBER.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "cms/member/rigister",
    data: member,
  })
    .then((data) => {
      dispatch({ type: ADD_MEMBER.SUCCESS });
      dispatch(getMember());
    })
    .catch((error) => dispatch({ type: ADD_MEMBER.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const editPersonalInfo = (memberId, member) => (dispatch) => {
  dispatch({ type: EDIT_PERSONAL_INFO.REQUEST });
  dispatch(showLoading());
  return patchAPI({
    url: `memberPersonal/${memberId}`,
    data: member,
  })
    .then((data) => dispatch({ type: EDIT_PERSONAL_INFO.SUCCESS }))
    .catch((error) => dispatch({ type: EDIT_PERSONAL_INFO.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const editMemberInfo = (memberId, member) => (dispatch) => {
  dispatch({ type: EDIT_MEMBER_INFO.REQUEST });
  dispatch(showLoading());
  return patchAPI({
    url: `register/${memberId}`,
    data: member,
  })
    .then((data) => dispatch({ type: EDIT_MEMBER_INFO.SUCCESS }))
    .catch((error) => dispatch({ type: EDIT_MEMBER_INFO.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const editMemberStatus = (member) => (dispatch) => {
  dispatch({ type: EDIT_MEMBER_STATUS.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: `changeMemberStatus`,
    data: { member },
  })
    .then((data) => dispatch({ type: EDIT_MEMBER_STATUS.SUCCESS }))
    .catch((error) => dispatch({ type: EDIT_MEMBER_STATUS.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getMember =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().member;
    dispatch(showLoading());
    dispatch({ type: GET_MEMBER_LIST.REQUEST });
    return getAPI({
      url: "cms/searchMember",
      params: {
        ...mapFilter(filter),
        $limit: limit,
        $skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_MEMBER_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_MEMBER_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getMemberById = (member_id) => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_MEMBER_BY_ID.REQUEST });
  return getAPI({
    url: "register",
    params: {
      member_id: member_id,
    },
  })
    .then((data) => dispatch({ type: GET_MEMBER_BY_ID.SUCCESS, payload: data }))
    .catch((error) => dispatch({ type: GET_MEMBER_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const approveDocumentMember = (body) => (dispatch, getState) => {
  dispatch(showLoading());
  return postAPI({
    url: "approveMember",
    data: body,
  })
    .then((data) => {
      dispatch({ type: APPROVE_DOCUMENT_MEMBER });
    })
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const requestDocumentMember = (body) => (dispatch, getState) => {
  dispatch(showLoading());
  return postAPI({
    url: "requestDocument",
    data: body,
  })
    .then((data) => {
      dispatch({ type: REQUEST_DOCUMENT_MEMBER });
    })
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const addSegment = (segment) => (dispatch) => {
  dispatch({ type: ADD_SEGMENT.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "common/segment",
    data: segment,
  })
    .then((data) => dispatch({ type: ADD_SEGMENT.SUCCESS }))
    .catch((error) => dispatch({ type: ADD_SEGMENT.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getSegment =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().member;
    dispatch(showLoading());
    dispatch({ type: GET_SEGMENT_LIST.REQUEST });
    return getAPI({
      url: "common/segment",
      params: {
        ...mapFilter(filter),
        $limit: limit,
        $skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_SEGMENT_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_SEGMENT_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const updateSegment = (segment, id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: PATCH_SEGMENT.REQUEST });
  return patchAPI({
    url: `common/segment/${id}`,
    data: segment,
  })
    .then((data) => dispatch({ type: PATCH_SEGMENT.SUCCESS }))
    .catch((error) => dispatch({ type: PATCH_SEGMENT.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const deleteSegmentItem = (segmentId) => (dispatch) => {
  dispatch({ type: DELETE_SEGMENT_ITEM.REQUEST });

  return patchAPI({
    url: `common/segment/${segmentId}`,
    data: { flag: "delete" },
  })
    .then(() =>
      dispatch({ type: DELETE_SEGMENT_ITEM.SUCCESS, payload: segmentId })
    )
    .catch((error) => dispatch({ type: DELETE_SEGMENT_ITEM.FAILURE, error }));
};

export const resendEmail = (member_id) => (dispatch) => {
  dispatch({ type: RESEND_VERIFY_EMAIL.REQUEST });

  return postAPI({
    url: `resendVerifyEmail`,
    data: { member_id, flag: "resendVerifyEmail" },
  })
    .then(() => dispatch({ type: RESEND_VERIFY_EMAIL.SUCCESS }))
    .catch((error) => dispatch({ type: RESEND_VERIFY_EMAIL.FAILURE, error }));
};

export const forgetPassword = (member_id) => (dispatch) => {
  dispatch({ type: FORGET_PASSWORD.REQUEST });

  return postAPI({
    url: `forgetpassword`,
    data: { member_id, flag: "forgetpassword" },
  })
    .then(() => dispatch({ type: FORGET_PASSWORD.SUCCESS }))
    .catch((error) => dispatch({ type: FORGET_PASSWORD.FAILURE, error }));
};

export const deleteUserSegmentItem = (user) => (dispatch) => {
  dispatch({ type: DELETE_USER_SEGMENT_ITEM.REQUEST });
  return postAPI({
    url: `addMemberSegment`,
    data: user,
  })
    .then(() =>
      dispatch({
        type: DELETE_USER_SEGMENT_ITEM.SUCCESS,
        payload: user.member[0].member_id,
      })
    )
    .catch((error) =>
      dispatch({ type: DELETE_USER_SEGMENT_ITEM.FAILURE, error })
    );
};

export const addUserSegment = (user) => (dispatch) => {
  dispatch({ type: ADD_USER_SEGMENT.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "addMemberSegment",
    data: user,
  })
    .then((data) => dispatch({ type: ADD_USER_SEGMENT.SUCCESS }))
    .catch((error) => dispatch({ type: ADD_USER_SEGMENT.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getSegmentById = (segmentId) => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_SEGMENT_BY_ID.REQUEST });
  return getAPI({
    url: `common/segment/${segmentId}`,
  })
    .then((data) => {
      dispatch({ type: GET_SEGMENT_BY_ID.SUCCESS, payload: { data: data } });
    })
    .catch((error) => dispatch({ type: GET_SEGMENT_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
