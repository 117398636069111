import { getAPI, postAPI } from "utils/api";
import { showLoading, hideLoading } from "redux/actions/loading";
import {
  GET_MASTER_DATA_PROVINCE,
  GET_MASTER_DATA_DISTRICT,
  GET_MASTER_DATA_SUB_DISTRICT,
  GET_MASTER_DATA_BANK,
  GET_MASTER_DATA_NATIONALITY,
  GET_MASTER_DATA_TAG,
  GET_MASTER_DATA_SUBJECT,
  GET_MASTER_DATA_JOB,
  GET_MASTER_DATA_JOB_LEVEL,
  GET_MASTER_DATA_ORGANIZATION,
} from "../actionTypes";

export const getMasterDataProvince = () => (dispatch) =>
  getAPI({
    url: "common/province",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_PROVINCE,
      payload: data.map((p) => {
        return { value: p.province_id, text: p.name_th };
      }),
    })
  );

export const getMasterDataDistrict = () => (dispatch) =>
  getAPI({
    url: "common/district",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_DISTRICT,
      payload: data.map((d) => {
        return {
          value: d.district_id,
          text: d.name_th,
          province_id: d.province_id,
        };
      }),
    })
  );

export const getMasterDataSubDistrict = () => (dispatch) =>
  getAPI({
    url: "common/subdistrict",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_SUB_DISTRICT,
      payload: data.map((s) => {
        return {
          value: s.sub_district_id,
          text: s.name_th,
          district_id: s.district_id,
          postcode: s.postcode,
        };
      }),
    })
  );

export const getMasterDataBank = () => (dispatch) =>
  getAPI({
    url: "common/bank",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_BANK,
      payload: data.map((b) => {
        return {
          value: b.bank_id,
          text: b.name_th,
        };
      }),
    })
  );

export const getMasterDataNationality = () => (dispatch) =>
  getAPI({
    url: "common/nationality",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_NATIONALITY,
      payload: data.reverse().map((b) => {
        return {
          value: b.name_th,
          text: b.name_th,
        };
      }),
    })
  );

export const getMasterDataTag = () => (dispatch) =>
  getAPI({
    url: "common/tag",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_TAG,
      payload: data.reverse().map((b) => {
        return {
          value: b.name_th,
          text: b.name_th,
        };
      }),
    })
  );

export const getMasterDataSubject = () => (dispatch) =>
  getAPI({
    url: "subjectList",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_SUBJECT,
      payload: data.map((s) => {
        return {
          value: s.id,
          text: s.name_upper,
        };
      }),
    })
  );

export const getMasterDataJobs = () => (dispatch) =>
  getAPI({
    url: "common/jobs",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_JOB,
      payload: data.map((s) => {
        return {
          value: s.id,
          text: s.name_th,
        };
      }),
    })
  );

export const getMasterDataJobLevels = () => (dispatch) =>
  getAPI({
    url: "common/jobslevel",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_JOB_LEVEL,
      payload: data.map((s) => {
        return {
          value: s.id,
          text: s.name,
        };
      }),
    })
  );

export const getMasterDataOrganization= () => (dispatch) =>
  getAPI({
    url: "common/organization",
    params: {
      $limit: 1000,
    },
  }).then((data) =>
    dispatch({
      type: GET_MASTER_DATA_ORGANIZATION,
      payload: data.map((s) => {
        return {
          value: s.id,
          text: s.name,
        };
      }),
    })
  );
