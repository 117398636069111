import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";

import imageLogo from "assets/images/logo-black.png";

const Div = styled.div`
  display: flex;
  height: 100vh;

  > .sidebar-left {
    display: flex;
    flex-direction: column;
    min-width: 296px;
    border-right: 1px solid #e8ecef;
    background: #000;
    color: #fff;

    .header-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      border-bottom: 1px solid #e8ecef;
      background: #fff;

      img {
        width: 160px;
        margin: 0 auto;
        height: auto;
      }
    }

    .user-wrapper {
      text-align: center;
      padding: 51px 12px;
      border-bottom: 1px solid #e8ecef;

      .image-profile {
        width: 140px;
        height: 140px;
        margin: 0 auto;
        background: #e8ecef;
        border-radius: 50%;
      }

      .email {
        margin-top: 33px;
        font-weight: bold;
      }
    }

    .main-menu {
      flex: 1;
      display: flex;
      flex-direction: column;
      /* overflow-y: auto; */

      a {
        position: relative;
        display: block;
        padding: 22px 32px;
        font-size: 14px;
        text-decoration: none;
        color: inherit;

        &.active {
          border-left: 3px solid #f8fafb;
        }
      }
    }

    .sub-menu {
      padding: 19px;
      border-top: 1px solid #e8ecef;
      text-align: center;

      a {
        font-weight: bold;
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  > .main-content {
    flex: 1;

    > .header-wrapper {
      height: 70px;
      border-bottom: 1px solid #e8ecef;
      font-family: "kelson_sansbold";
      font-size: 20px;
      padding: 22px 60px;
      background: #fff;
    }

    > .content {
      padding: 29px 60px;
      height: calc(100% - 70px);
      overflow-y: auto;
    }
  }
`;

export default ({ title, hideMenu, children }) => {
  const { email } = localStorage.getItem(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : { email: "" };

  return (
    <Div>
      <div className="sidebar-left">
        <div className="header-wrapper">
          <img src={imageLogo} />
        </div>
        <div className="user-wrapper">
          <div className="image-profile" />
          {/* <div className="email">{email}</div> */}
        </div>
        <div className="main-menu">
          {!hideMenu && (
            <>
              <NavLink
                className="link-home"
                activeClassName="active"
                to="/"
                exact
              >
                Home
              </NavLink>
              <NavLink
                className="link-library"
                activeClassName="active"
                to="/library"
              >
                Center Libary
              </NavLink>
              <NavLink
                className="link-member"
                activeClassName="active"
                to="/member"
              >
                Member Management
              </NavLink>
              <NavLink
                className="link-order"
                activeClassName="active"
                to="/order"
              >
                Order Management
              </NavLink>
              <NavLink
                className="link-report"
                activeClassName="active"
                to="/report"
              >
                Report Management
              </NavLink>
              <NavLink
                className="link-campaign"
                activeClassName="active"
                to="/campaignMngt"
              >
                Campaign Management
              </NavLink>
              <NavLink
                className="link-campaign"
                activeClassName="active"
                to="/pre-assessment"
              >
                Report
              </NavLink>
            </>
          )}
        </div>
        <div className="sub-menu">
          <Link className="link-logout" to="/logout">
            Log out
          </Link>
        </div>
      </div>
      <div className="main-content">
        <div className="header-wrapper">{title}</div>
        <div className="content">{children}</div>
      </div>
    </Div>
  );
};
