import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import Date from "components/form/Date";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import FileCell from "components/table/FileCell";
import StatusCell from "components/table/StatusCell";
import CheckCell from "components/table/CheckCell";
import PopupAddBusinessMember from "containers/popup/PopupAddBusinessMember";
import PopupAddIndividualMember from "containers/popup/PopupAddIndividualMember";
import PopupCreateMember from "containers/popup/PopupCreateMember";
import PopupApproveDocument from "containers/popup/PopupApproveDocument";
import PopupChangeStatusMember from "../../containers/popup/PopupChangeStatusMember";

import {} from "redux/selectors";
import {
  getMember,
  getMemberById,
  resendEmail,
  forgetPassword,
} from "redux/actions/member";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 1rem 0;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const initFilter = {
  id: "",
  email: "",
  firstName: "",
  memberStatus: "",
  memberTypeName: "",
  organizationId: "",
  organizationName: "",
  organizationType: "",
  phone: "",
};

const memberTypeList = [{ value: 1, text: "Normal" }];

const MemberAll = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.member);
  const [memberList, setMemberList] = useState(items);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [selectedMemberId, setSelectedMemberId] = useState([]);

  const [isOpenCreateMember, setIsOpenCreateMember] = useState(false);
  const [isOpenPopupStatus, setIsOpenPopupStatus] = useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    console.log("items", items);
    let index = 1;
    let newItems = items.map((it) => {
      return { ...it, no: index++ };
    });
    setMemberList(newItems);
  }, [items]);

  const LinkDetailCell = ({ value, row }) => {
    return (
      <RouterLinkCell to={`/member/detail/${row?.id}`}>{value}</RouterLinkCell>
    );
  };

  const StatusCellWrapper = ({ value }) => {
    let type = "";
    if (value === 1) {
      type = "Individual";
    } else if (value === 2) {
      type = "Business";
    }
    return <StatusCell type={type}>{type}</StatusCell>;
  };

  const StatusCellApproveStatus = ({ value }) => {
    let type = "";
    if (value === null || !value) return "-";
    return <StatusCell type={value}>{value}</StatusCell>;
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  // const ApproveDocument = ({ value }) => {
  //   return (
  //     <Button onClick={() => handleApproveDocument(value)}>
  //       Approve Document
  //     </Button>
  //   );
  // };

  const ActionResendEmailCell = ({ row }) => {
    return (
      <Button onClick={() => handleResendEmail(row?.id)}>Resend Email</Button>
    );
  };

  const ActionForgetPasswordCell = ({ row }) => {
    return (
      <Button onClick={() => handleForgetPassword(row?.id)}>
        Forget Password
      </Button>
    );
  };

  const ActionUpgradeUserCell = ({ row }) => {
    return (
      <Button
        style={{ width: "100%" }}
        onClick={() => history.push(`/member/detail/${row.id}`)}
      >
        View
      </Button>
      // /member/detail/:id
    );
  };

  const handleUpgrade = () => {};

  const columns = [
    {
      field: "no",
      headerName: "ลำดับ",
      width: 80,
    },
    {
      field: "id",
      headerName: "Member ID",
      width: 200,
    },
    {
      field: "userName",
      headerName: "Username",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "memberTypeName",
      headerName: "Type",
      width: 200,
    },
    {
      field: "memberStatus",
      headerName: "Status",
      width: 200,
    },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 200 },

    {
      field: "upgradeUser",
      headerName: "Action",
      renderCell: ActionUpgradeUserCell,
      width: 200,
    },
    {
      field: "registerDate",
      headerName: "Registered Date",
      width: 200,
    },
    // {
    //   field: "approveStatusFormatted",
    //   headerName: "Approve Status",
    //   renderCell: StatusCellApproveStatus,
    //   width: 200,
    // },
    // {
    //   field: "-",
    //   headerName: "Action",
    //   renderCell: ActionResendEmailCell,
    //   width: 200,
    // },
    // {
    //   field: "",
    //   headerName: "Action Forget",
    //   renderCell: ActionForgetPasswordCell,
    //   width: 200,
    // },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getMember(initFilter));
  };

  const handlePageChange = (nextPage) => {
    console.log("handlePageChange");
    setPage(nextPage);
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getMember(filter));
  };

  const handleCloseIndividualPopup = () => {
    setIsOpenCreateMember(false);
    // handleSearch();
  };

  const handleAddIndividualMember = () => {
    setIsOpenCreateMember(true);
  };

  const handleOpenPopupStatus = () => {
    setIsOpenPopupStatus(true);
  };

  const handleClosePopupStatus = () => {
    setIsOpenPopupStatus(false);
    window.location.reload();
  };

  const handleEditMember = (dataRow) => {};

  const handleResendEmail = (memberId) => {
    dispatch(resendEmail(memberId));
  };
  const handleForgetPassword = (memberId) => {
    dispatch(forgetPassword(memberId));
  };

  const handleSelectMember = (idList) => {
    let newArr = [];
    for (const id of idList) {
      let item = memberList.find((it) => it.id === id);
      newArr.push({
        id: item?.id,
        name: `${item?.firstName} ${item?.lastName}`,
        status: item?.memberStatus,
      });
    }

    setSelectedMemberId(newArr);
  };

  const handleDeleteMember = () => {};

  return (
    <SidebarLayout title="Member Management">
      {Boolean(isOpenCreateMember) && (
        <PopupCreateMember open onClose={handleCloseIndividualPopup} />
      )}

      {isOpenPopupStatus && (
        <PopupChangeStatusMember
          open
          onClose={handleClosePopupStatus}
          selectList={selectedMemberId}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/member/all", text: "All" },
            { path: "/member/segment", text: "Segment" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Filter</div>
              <div className="group-field">
                <Select
                  label={"Status"}
                  value={filter.memberStatus}
                  items={memberTypeList}
                  onChange={(e) =>
                    handleFilterChange("memberStatus", e.target.value)
                  }
                  name="memberStatus"
                />
                <Select
                  label={"Type"}
                  value={filter.memberTypeName}
                  items={memberTypeList}
                  onChange={(e) =>
                    handleFilterChange("memberTypeName", e.target.value)
                  }
                  name="memberTypeName"
                />
              </div>
              <div className="group-title">Search Member</div>
              <div className="group-field">
                <TextField
                  label="Email"
                  value={filter.email}
                  onChange={(e) => handleFilterChange("email", e.target.value)}
                  name="email"
                />
                <TextField
                  label="Name"
                  value={filter.firstName}
                  onChange={(e) =>
                    handleFilterChange("firstName", e.target.value)
                  }
                  name="firstName"
                />
                <TextField
                  label="Phone Number"
                  value={filter.phone}
                  onChange={(e) => handleFilterChange("phone", e.target.value)}
                  name="phone"
                />
                <TextField
                  label="Member ID"
                  value={filter.id}
                  onChange={(e) =>
                    handleFilterChange("memberId", e.target.value)
                  }
                  name="memberId"
                />
              </div>
            </div>
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  {/* <Button
                    disabled={selectedMemberId.length === 0}
                    onClick={handleOpenPopupStatus}
                  >
                    change stutus member
                  </Button> */}
                  <Button
                    id="btn-add-member-individual"
                    onClick={handleAddIndividualMember}
                  >
                    Create Member
                  </Button>
                  {/* <Button
                    id="btn-add-member-business"
                    onClick={handleAddBusinessMember}
                  >
                    Add Business Member
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={memberList}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              // onSelectionChange={handleSelectMember}
              // disableSelectionOnClick={false}
              // onEditRow={handleEditMember}
              // onDeleteRow={handleDeleteMember}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default MemberAll;
