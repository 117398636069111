import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Select from "components/form/Select";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

import { Grid } from "@material-ui/core";
import { ArrowBack as BackIcon, Edit as EditIcon } from "@material-ui/icons";

import {
  getCustomQuestionById,
  updateCustomQuestionById,
} from "redux/actions/library";
import ButtonIconDeleteCircle from "components/form/ButtonIconDeleteCircle";
import ButtonIconAddCircle from "components/form/ButtonIconAddCircle";

import Checkbox from "@mui/material/Checkbox";

import { uploadImage } from "redux/actions/share";
import ButtonUploadFile from "components/form/ButtonUploadFile";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }
  .table-wrapper {
    button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }
  .headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    height: 35px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }
  .border-grid {
    border: 1px solid #000;
  }
  .fix-height {
    height: 30px;
  }
  .btn-edit {
    color: #a32330;
    border-color: #a32330;
    padding: 0 auto;
    margin: 10px 0;
    height: 40px;
    :disabled {
      opacity: 0.5;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 100%;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          /* min-width: 150px; */
          height: 47px;
        }
      }
    }
  }

  .choice_container {
    display: flex;
    margin-bottom: 5px;

    .choice_selection {
      margin-right: 5px;
    }
    .choice_field {
      min-width: 550px;
      margin-right: 5px;
    }
    .choice_btn {
    }
  }
`;

const statusList = [
  { value: "essay", text: "Essay" },
  { value: "multiple-choice", text: "Multiple Choices" },
];

const initChoiceFrm = [
  {
    choice_description: "",
    choice_type: "answer",
    is_answer: false,
    answer_point: 1,
    image_url: "",
    vdo_url: "",
  },
];

const initCustomQuestion = {
  id: null,
  question_title: "",
  question_type: "",
  question_time: 0,
  video_url: "",
  image_url: "",
  question_description: "",
  is_shuffle_answer: 0,
  status: "",
  company_id: null,
  is_published: 0,
  createdAt: "",
  updatedAt: "",
};

const AdditionalQuestionCustom = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { customQuestionDetail } = useSelector((state) => state.library);

  const [choices, setChoices] = useState(initChoiceFrm);
  const [from, setFrom] = useState(initCustomQuestion);
  const [image_url, setURL_Image] = useState("");

  useEffect(() => {
    fetchCustomQuestionById();
  }, []);

  useEffect(() => {
    console.log("customQuestionDetail", customQuestionDetail);
    setFrom(customQuestionDetail);

    setChoices(
      customQuestionDetail?.choice?.map((it) => ({
        choice_no: it.choice_no,
        choice_description: it.choice_description,
        choice_type: "answer",
        is_answer: it.is_answer == 0 ? false : true,
        answer_point: it.answer_point,
        image_url: it.image_url,
        vdo_url: it.vdo_url,
        id: it.id,
        custom_questions_id: it.custom_questions_id,
      }))
    );
  }, [customQuestionDetail]);

  const handleDeleteItem = (index) => {
    let newList = [...choices];

    if (index > 0) {
      newList.splice(index, 1);
    }
    setChoices(newList);
  };

  const handleAddItem = (value) => {
    setChoices([
      ...choices,
      {
        choice_description: "",
        choice_type: "answer",
        is_answer: false,
        answer_point: 1,
        image_url: "",
        vdo_url: "",
      },
    ]);
  };

  const handleSave = () => {
    console.log("choices", choices);
    let choice = choices.map((it, index) => {
      return { ...it, choice_no: index++ };
    });

    from.choice = choice;
    dispatch(updateCustomQuestionById(from, id)).then(() => history.goBack());
  };

  const fetchCustomQuestionById = useCallback(() => {
    dispatch(getCustomQuestionById(id));
  }, [id, dispatch]);

  const handleCheckboxChange = (value, index) => {
    let newArr = [...choices];
    newArr[index].is_answer = !value;
    setChoices(newArr);
  };

  const handleChoiceFrmChange = (value, index) => {
    let newArr = [...choices];
    newArr[index].choice_description = value;
    setChoices(newArr);
  };

  const handleFromChange = (key, value) => {
    setFrom({
      ...from,
      [key]: value,
    });
  };

  const handleUploadImage = (e) => {
    e.persist();
    dispatch(uploadImage(e.target.files[0])).then(({ path }) => {
      setFrom({
        ...from,
        ['image_url']: path,
      });
      
    });
  };

  return (
    <SidebarLayout title="Examination">
      <Div>
        <SubMenu
          menu={[
            { path: "/library/examseries/all", text: "ชุดข้อสอบ" },
            { path: "/library/examination/all", text: "ข้อสอบ" },
            { path: "/library/examiner/all", text: "ผู้เข้าสอบ" },
            { path: "/library/question/all", text: "คำถามเพิ่มเติม" },
            { path: "/library/subject/all", text: "วิชา" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <Grid container spacing={5}>
              <Grid item xs={12} className="headbar-panel-wrapper">
                <BackIcon
                  className="back-icon"
                  onClick={() => history.goBack()}
                />
                <span className="title">Custom Question</span>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={4}>
                  <div className="section-group">
                    <div className="group-title">Question Type*</div>
                    <div className="group-field">
                      <Select
                        defaultValue={"Essay"}
                        value={from?.question_type}
                        items={statusList}
                        disabled
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="section-group">
                  <div className="group-title">Title Question*</div>
                  <div className="group-field">
                    <TextField
                      value={from?.question_title}
                      onChange={(e) =>
                        handleFromChange("question_title", e.target.value)
                      }
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="section-group">
                  <div className="group-title">
                    Title Question Image (if any)
                  </div>
                  <div className="group-field">
                    <ButtonUploadFile
                      defaultValue={from.image_url}
                      id={`upload-file`}
                      onChange={(e) => handleUploadImage(e)}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="section-group">
                  <div className="group-title">Time to answer (min)*</div>
                  <div className="group-field">
                    <TextField
                      value={from?.question_time}
                      onChange={(e) =>
                        handleFromChange("question_time", e.target.value)
                      }
                    />
                  </div>
                </div>
              </Grid>
              {from?.question_type === "multiple-choice" && (
                <Grid item xs={12}>
                  <Grid item xs={4}>
                    <div className="question-group">
                      <div className="question-title">Choice</div>
                      <div className="question-title">
                        (Select the correct answer, if any)
                      </div>
                      <div className="question-item">
                        {choices?.map((item, index) => (
                          <div className="choice_container" key={index}>
                            <div>
                              <Checkbox
                                id={`checkbox-answer-${index}`}
                                checked={item?.is_answer}
                                onChange={(e) => {
                                  handleCheckboxChange(item.is_answer, index);
                                }}
                              />
                            </div>
                            <div className="choice_field">
                              <TextField
                                placeholder="Choice"
                                value={item?.choice_description}
                                className="field_choice"
                                onChange={(e) =>
                                  handleChoiceFrmChange(e.target.value, index)
                                }
                              />
                            </div>
                            <div className="choice_btn">
                              {index !== 0 && (
                                <ButtonIconDeleteCircle
                                  id={`btn-remove-${index}-choice`}
                                  onClick={() => handleDeleteItem(index)}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                        <div>
                          <ButtonIconAddCircle
                            onClick={() => handleAddItem()}
                          />{" "}
                          Add more choice
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} className="headbar-panel-wrapper">
                <div className="section-group ">
                  <div className="group-field end">
                    <Button onClick={() => handleSave()}>Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default AdditionalQuestionCustom;
