import { GET_PRE_ASSESSMENT_LIST } from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

export const getPreAssessmentList =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_PRE_ASSESSMENT_LIST.REQUEST });
    return getAPI({
      url: "cms/preassessment_report",
      params: {
        ...filter,
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_PRE_ASSESSMENT_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_PRE_ASSESSMENT_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };
