import {
  CREATE_EXAM_CAMPAIGN,
  CREATE_REPORT_CONFIG,
  GET_CAMPAIGN_LIST,
  GET_DISC_RESULT_LIST,
  GET_EXAM_CAMPAIGN_BY_DATE,
  GET_DETAIL_QUESTION_BY_CAMPAIGN,
  GET_DETAIL_CUSTOM_QUESTION_BY_CAMPAIGN,
  GET_EXAM_CAMPAIGN_LIST,
  GET_REPORT_CONFIG_BY_ID,
  GET_REPORT_CONFIG_LIST,
  GET_REPORT_CONFIG_LIST_DROPDOWN,
  PATCH_EXAM_CAMPAIGN_STATUS,
  PATCH_EXAM_STATUS,
  PATCH_REPORT_CONFIG,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

import Moment from "moment";

import { ExportToCsv } from "export-to-csv";

const mapFilter = (v) => ({
  id: v.id || null,
  first_name: v.firstName || null,
  last_name: v.lastName || null,
  compatibility_less: v.compatibilityLess || null,
  compatibility_more: v.compatibilityMore || null,
  exam_result_less: v.examResultLess || null,
  exam_result_more: v.examResultMore || null,
  status: v.status || null,
  disc: v.disc || null,
  campaign_type: v.campaignType || null,
  start_date: v.startDate || null,
  end_date: v.endDate || null,
});

const mapReportConfigFilter = (v) => ({
  report_name: v.reportName || null,
  report_create_by: v.reportCreateBy || null,
  report_create_date: v.reportCreateDate || null,
  report_total_subject: v.reportTotalSubject || null,
});

const mapCampaignFilter = (v) => ({
  name: v.name || null,
  companyName: v.companyName || null,
  start_date: v.startDate || null,
  end_date: v.endDate || null,
  status: v.status || null,
  campaign_type: v.campaignType || null,
});

const options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: `report${new Date().getTime()}-${Math.floor(
    Math.random() * 100000000 + 1
  )}`,
};

const csvExporter = new ExportToCsv(options);

export const getExamCampaigns =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().report;
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_CAMPAIGN_LIST.REQUEST });
    return getAPI({
      url: "examCampaign",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_EXAM_CAMPAIGN_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_EXAM_CAMPAIGN_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const createCampaign = (obj) => (dispatch) => {
  dispatch({ type: CREATE_EXAM_CAMPAIGN.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "examCampaign",
    data: obj,
  })
    .then((data) => {
      dispatch({ type: CREATE_EXAM_CAMPAIGN.SUCCESS });
    })
    .catch((error) => dispatch({ type: CREATE_EXAM_CAMPAIGN.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamCampaignByDate =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().report;
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.REQUEST });
    return getAPI({
      url: "examCampaign",
      params: {
        ...mapFilter(filter),
        $limit: limit,
        $skip: skip,
      },
    })
      .then((data) => {
        console.log(data);
        if (data.length > 0) {
          csvExporter.generateCsv(data?.data);
        }
        dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.SUCCESS });
      })
      .catch((error) =>
        dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const updateExamStatus = (examUserStatusId) => (dispatch) => {
  dispatch({ type: PATCH_EXAM_STATUS.REQUEST });
  dispatch(showLoading());
  return patchAPI({
    url: `examUser/${examUserStatusId}`,
  })
    .then((data) => {
      dispatch({ type: PATCH_EXAM_STATUS.SUCCESS });
    })
    .catch((error) => dispatch({ type: PATCH_EXAM_STATUS.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createReportConfig = (obj) => (dispatch) => {
  dispatch({ type: CREATE_REPORT_CONFIG.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "reportConf",
    data: obj,
  })
    .then((data) => {
      dispatch({ type: CREATE_REPORT_CONFIG.SUCCESS });
    })
    .catch((error) => dispatch({ type: CREATE_REPORT_CONFIG.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getDiscResults =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().report;
    dispatch(showLoading());
    dispatch({ type: GET_DISC_RESULT_LIST.REQUEST });
    return getAPI({
      url: "discResultList",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_DISC_RESULT_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_DISC_RESULT_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getReportConfigs =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_REPORT_CONFIG_LIST.REQUEST });
    return getAPI({
      url: "reportConfList",
      params: {
        ...mapReportConfigFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_REPORT_CONFIG_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_REPORT_CONFIG_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getReportConfigById = (id) => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_REPORT_CONFIG_BY_ID.REQUEST });
  return getAPI({
    url: `reportConf/${id}`,
  })
    .then((data) => {
      console.log(data);
      dispatch({ type: GET_REPORT_CONFIG_BY_ID.SUCCESS, payload: data });
    })
    .catch((error) =>
      dispatch({ type: GET_REPORT_CONFIG_BY_ID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const clearStateReportConfig = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_REPORT_CONFIG_BY_ID.SUCCESS, payload: {} });
  dispatch(hideLoading());
};

export const updateReportConfig = (reportConfigData, id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: PATCH_REPORT_CONFIG.REQUEST });
  return patchAPI({
    url: `reportConf/${id}`,
    data: reportConfigData,
  })
    .then((data) => dispatch({ type: PATCH_REPORT_CONFIG.SUCCESS }))
    .catch((error) => dispatch({ type: PATCH_REPORT_CONFIG.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCampaigns =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_CAMPAIGN_LIST.REQUEST });
    return getAPI({
      url: "examCampaignList",
      params: {
        ...mapCampaignFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_CAMPAIGN_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_CAMPAIGN_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getReportConfigsDropdown =
  (filter = {}, skip = 0, limit = 1000) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_REPORT_CONFIG_LIST_DROPDOWN.REQUEST });
    return getAPI({
      url: "reportConfList",
      params: {
        ...mapReportConfigFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({
          type: GET_REPORT_CONFIG_LIST_DROPDOWN.SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({ type: GET_REPORT_CONFIG_LIST_DROPDOWN.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const generateReportConfigsCSV =
  (campaignId, reportConfId) => (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.REQUEST });
    return getAPI({
      url: `examCampaignList/${campaignId}?reportID=${reportConfId}`,
    })
      .then((data) => {
        console.log(data);
        if (data.length > 0) {
          csvExporter.generateCsv(data);
        }
        dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.SUCCESS });
      })
      .catch((error) =>
        dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getDetailQuestionByCampaign = (params) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_DETAIL_QUESTION_BY_CAMPAIGN.REQUEST });
  return getAPI({
    url: `cms/examResultDetail`,
    params: {
      slug: params?.slug,
      userUid: params?.userUid,
    },
  })
    .then((data) => {
      dispatch({
        type: GET_DETAIL_QUESTION_BY_CAMPAIGN.SUCCESS,
        payload: data,
      });
    })
    .catch((error) =>
      dispatch({ type: GET_DETAIL_QUESTION_BY_CAMPAIGN.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getDetailCustomQuestionByCampaign = (params) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_DETAIL_CUSTOM_QUESTION_BY_CAMPAIGN.REQUEST });
  return getAPI({
    url: `cms/examCustomResultDetail`,
    params: {
      slug: params?.slug,
      userUid: params?.userUid,
    },
  })
    .then((data) => {
      dispatch({
        type: GET_DETAIL_CUSTOM_QUESTION_BY_CAMPAIGN.SUCCESS,
        payload: data,
      });
    })
    .catch((error) =>
      dispatch({ type: GET_DETAIL_CUSTOM_QUESTION_BY_CAMPAIGN.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateCampaignStatus = (campaignId) => (dispatch) => {
  dispatch({ type: PATCH_EXAM_CAMPAIGN_STATUS.REQUEST });
  dispatch(showLoading());
  return patchAPI({
    url: `examCampaignList/${campaignId}`,
  })
    .then((data) => {
      dispatch({ type: PATCH_EXAM_CAMPAIGN_STATUS.SUCCESS });
    })
    .catch((error) =>
      dispatch({ type: PATCH_EXAM_CAMPAIGN_STATUS.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};
