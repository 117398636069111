import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import { IconButton } from "@material-ui/core";
import {
  DeleteOutlined as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import ReactPaginate from "react-paginate";

const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    outline: none !important;
  }

  .Mui-checked {
    color: #080808 !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-size: 16px;
    font-weight: bold;
    color: #080808;
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 !important;
  }

  .MuiDataGrid-columnSeparator {
    /* display: none !important; */
  }

  .MuiDataGrid-cell {
    font-size: 16px;
    color: #080808;
  }

  .MuiDataGrid-footerContainer {
    justify-content: center;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      li {
        margin: 0 5px;
        list-style: none;

        a {
          display: inline-block;
          width: 40px;
          height: 40px;
          padding: 10px 0;
          text-align: center;
          font-size: 14px;
          color: #080808;
          border-radius: 4px;
          cursor: pointer;
        }

        &.selected {
          a {
            color: #ffffff;
            background: #080808;
          }
        }

        &.previous,
        &.next {
          margin: 0 14px;

          a {
            padding: 0;
            width: auto;
            height: auto;
          }

          svg {
            position: relative;
            font-size: 16px;
            margin: 0 7px;
            top: 1px;
          }
        }

        &.disabled {
          a {
            color: #b6b8c1;
            cursor: default;
          }
        }
      }
    }
  }
`;

const appendDeleteButton = (columns, onClick = () => {}) => {
  const DeleteMenuCell = ({ row }) => {
    const handleClick = () => onClick(row);
    return (
      <IconButton size="small" onClick={handleClick}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    );
  };

  return [
    ...columns,
    {
      field: "removeButton",
      headerName: "",
      width: 50,
      renderCell: DeleteMenuCell,
    },
  ];
};

const appendEditButton = (columns, onClick = () => {}) => {
  const EditMenuCell = ({ row }) => {
    const handleClick = () => onClick(row);
    return (
      <IconButton size="small" onClick={handleClick}>
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  return [
    ...columns,
    {
      field: "editButton",
      headerName: "",
      width: 50,
      renderCell: EditMenuCell,
    },
  ];
};

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const { state, setPage } = apiRef.current;

  const nextLabel = (
    <span>
      Next <FontAwesomeIcon icon={["fas", "chevron-right"]} />
    </span>
  );
  const previousLabel = (
    <span>
      <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Prev
    </span>
  );

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={nextLabel}
      onPageChange={({ selected }) => setPage(selected)}
      pageRangeDisplayed={3}
      pageCount={state.pagination.pageCount}
      previousLabel={previousLabel}
      forcePage={state.pagination.page}
      renderOnZeroPageCount={null}
    />
  );
};

const Table = ({
  columns = [],
  rows = [],
  rowCount = 0,
  pageSize = 10,
  page,
  checkboxSelection = true,
  onPageChange,
  onSelectionChange,
  onDeleteRow,
  onEditRow,
  disableSelectionOnClick = false,
}) => {
  let customColumns = columns;

  if (onEditRow) {
    customColumns = appendEditButton(customColumns, onEditRow);
  }
  if (onDeleteRow) {
    customColumns = appendDeleteButton(customColumns, onDeleteRow);
  }

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const mapColumnProp = (props) => {
    const { width } = props;
    return {
      sortable: false,
      flex: width ? 0 : 1,
      headerAlign: "center",
      align: "center",
      renderCell: IsNullValue,
      ...props,
    };
  };

  return (
    <StyledDataGrid
      columns={customColumns.map(mapColumnProp)}
      rows={rows}
      rowCount={rowCount}
      pageSize={pageSize}
      page={page}
      paginationMode="server"
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={onSelectionChange}
      disableSelectionOnClick={disableSelectionOnClick}
      autoHeight
      disableColumnFilter
      disableColumnMenu
      rowsScrollEnd
      columnResize
      hideFooterSelectedRowCount
      onPageChange={onPageChange}
      components={{
        Pagination: CustomPagination,
      }}
    />
  );
};

export default Table;
