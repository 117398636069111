import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import Date from "components/form/Date";
import moment from "moment";

import { addMember } from "redux/actions/member";

import {
  selectMasterDataProvince,
  selectMasterDataDistrict,
  selectMasterDataSubDistrict,
  selectMasterDataBank,
  selectMasterDataNationality,
} from "../../redux/selectors";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    /* background: #f7f9fa;
    border-radius: 50%; */

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const initForm = {
  first_name: "",
  email: "",
  phone: "",
  member_type: 1,
  regis_channel: "email",
  createdAt: "",
};

const types = [{ value: 1, text: "Normal" }];

const PopupCreateMember = ({
  open,
  onClose,
  label,
  dataEdit = {},
  isUploading,
  maxWidth,
  fileName,
  percentage,
  error,
}) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(initForm);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log("form", form);
  }, [form]);

  const onChangeForm = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitAddMember = () => {
    console.log("submitAddMember", form);
    dispatch(addMember(form));
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <form onSubmit={submitAddMember}>
          <div className="form-wrapper">
            <div className="title">Add Individual Member</div>

            <p className="form-topic">ข้อมูล</p>
            <InlineSpaceText label="Name">
              <TextField
                name="first_name"
                value={form.first_name}
                onChange={(e) => {
                  onChangeForm(e);
                  // validate({ ["firstName"]: e.target.value });
                }}
                className="field-firstName"
                // error={errors.firstName}
              />
            </InlineSpaceText>
            <InlineSpaceText label="Email">
              <TextField
                name="email"
                value={form.email}
                onChange={(e) => {
                  onChangeForm(e);
                  // validate({ ["email"]: e.target.value });
                }}
                className="field-email"
                // error={errors.email}
              />
            </InlineSpaceText>
            <InlineSpaceText label="Phone Number">
              <TextField
                name="phone"
                value={form.phone}
                onChange={(e) => {
                  onChangeForm(e);
                  // validate({ ["phone"]: e.target.value });
                }}
                className="field-phone"
                // error={errors.phone}
              />
            </InlineSpaceText>
            <InlineSpaceText label="วันที่สมัคร">
              <Date
                name="createdAt"
                placeholder="วันที่เริ่มต้น"
                value={form.createdAt}
                disableFuture
                onChange={(date) =>
                  setForm((prev) => ({
                    ...prev,
                    createdAt: moment(date).format("YYYY-MM-DD"),
                  }))
                }
              />
            </InlineSpaceText>
            <InlineSpaceText label="Type">
              <Select
                items={types}
                value={form.member_type}
                onChange={(e) => onChangeForm(e)}
                defaultValue="เลือกจังหวัด"
                className="field-province"
                id="member_type"
                name="member_type"
                // errorText={errors.province}
                // isError={errors.province}
              />
            </InlineSpaceText>
          </div>

          <div className="button-wrapper">
            <Button onClick={onClose} outlined>
              Cancel{" "}
            </Button>
            <ButtonBlack type="submit">Add</ButtonBlack>
          </div>
        </form>{" "}
      </Div>
    </BaseModal>
  );
};

export default PopupCreateMember;
