import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";

import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import Select from "components/form/Select";
import { Grid } from "@material-ui/core";

import StepBar from "components/form/StepBar";
import TextField from "components/form/TextField";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import TextEditor from "components/form/TextEditor";

import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";
import { addExamQuestion } from "redux/actions/library";
import { id } from "date-fns/locale";

import FormHelperText from "@material-ui/core/FormHelperText";

import Autocomplete from "@mui/material/Autocomplete";
import { TextField as MuiTextField } from "@mui/material/TextField";
import RichTextEditor from "components/form/RichTextEditor";
import { map } from "lodash";

const Div = styled.div`
  padding: 20px;
  width: 1200px;

  .stepbar-container {
    padding: 0 250px;
    margin-top: 10px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
        padding: 6px;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        align-items: start;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 550px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }
      }
    }

    .text-right {
      text-align: right;
    }

    .invilid {
      color: red;
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .answer-container {
    display: flex;
    .answer-item {
    }
  }

  .button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
    margin: 0 !important;
    height: 0 !important;
    min-width: 0 !important;
  }
`;

const steps = ["กรอกข้อมูลข้อสอบ", "สร้างโจทย์"];

const difficultyLevelList = [
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
  { value: 5, text: 5 },
  { value: 6, text: 6 },
  { value: 7, text: 7 },
  { value: 8, text: 8 },
  { value: 9, text: 9 },
  { value: 10, text: 10 },
  { value: 11, text: 11 },
];
const numberAnswersList = [{ value: null, text: "จำนวนคำตอบ" }];
const positionList = [{ value: null, text: "งานตำแหน่ง" }];

const PopupManageExamQuestion = ({
  open,
  onClose,
  maxWidth = "xl",
  invoiceId,
}) => {
  const dispatch = useDispatch();
  const masterDataSubject = useSelector(selectMasterSubject);
  const masterDataJob = useSelector(selectMasterJob);

  const [dataSubject, setDataSubject] = useState(masterDataSubject);
  const [dataJobs, setDataJobs] = useState(
    masterDataJob.map((it) => ({ label: it.text, id: it.value }))
  );
  const [subjectId, setSubjectId] = useState("");
  const [topicName, setTopicName] = useState("");
  const [examOwner, setExamOwner] = useState("");
  const [level, setLevel] = useState("");
  const [amountAnswer, setAmountAnswer] = useState("");
  const [jobPositionId, setJobPositionId] = useState("");

  const [isRandom, setIsRandom] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const [imgUrl, setImgUrl] = useState();
  const [indexImgUrl, setIndexImgUrl] = useState();

  const [propositionFrm, setPropositionFrm] = useState([
    {
      choice_no: 0,
      choice_description: "",
      choice_type: "question",
      is_answer: false,
      answer_point: 0,
      titleText: "โจทย์",
      choice_imgUrl: "",
    },
  ]);

  const [errors, setErrors] = useState({});
  const [errorsProposition, setErrorsProposition] = useState({});

  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    let subjectArr = masterDataSubject.map((it) => ({
      label: it.text,
      id: it.value,
    }));
    setDataSubject(subjectArr);
  }, [masterDataSubject]);

  useEffect(() => {
    let arr = map((it) => it.id);
    console.log(arr);
    console.log(dataJobs);
    const duplicates = arr.filter((item, index) => index !== arr.indexOf(item));
    console.log(duplicates);
  }, [dataJobs]);

  const validateStep1 = (
    fieldValues = {
      subjectId,
      topicName,
      examOwner,
      level,
      amountAnswer,
      jobPositionId,
    }
  ) => {
    let temp = { ...errors };
    if ("subjectId" in fieldValues)
      temp.subjectId = fieldValues.subjectId !== "" ? "" : "กรุณาเลือกวิชา";
    if ("topicName" in fieldValues)
      temp.topicName = fieldValues.topicName !== "" ? "" : "กรุณากรอกหัวข้อ";
    if ("examOwner" in fieldValues)
      temp.examOwner =
        fieldValues.examOwner !== "" ? "" : "กรุณากรอกชื่อผู้ออกข้อสอบ";
    if ("level" in fieldValues)
      temp.level = fieldValues.level !== "" ? "" : "กรุณาเลือก Level";
    if ("amountAnswer" in fieldValues)
      temp.amountAnswer =
        fieldValues.amountAnswer === ""
          ? "กรุณากรอกจำนวนคำตอบให้ถูกต้อง (จำนวนคำตอบกรอกได้ไม่เกิน 4 ข้อ)"
          : "";
    if ("jobPositionId" in fieldValues)
      temp.jobPositionId =
        fieldValues.jobPositionId !== "" ? "" : "กรุณาเลือกงานตำแหน่ง";

    setErrors({ ...temp });
    return (
      subjectId === "" ||
      topicName === "" ||
      examOwner === "" ||
      level === "" ||
      amountAnswer === "" ||
      jobPositionId === ""
    );
  };

  const validateStep2 = () => {
    let temp = { ...errorsProposition };

    const propositions = propositionFrm?.map((value) => ({
      choice_description:
        value.choice_description !== "" ? "" : `กรุณากรอก ${value.titleText}`,
    }));

    temp.propositions = propositions;

    setErrorsProposition({ ...temp });

    return propositions.every((it) => it.choice_description === "");
  };

  const nexStep = (e) => {
    e.preventDefault();

    if (!validateStep1()) {
      setCurrentStep(1);

      // let newArr = [...propositionFrm];
      let newArr = [];
      for (let index = 0; index < amountAnswer; index++) {
        newArr.push({
          choice_no: index + 1,
          choice_description: "",
          choice_type: "answer",
          is_answer: false,
          answer_point: 1,
          titleText: `คำตอบที่ ${index + 1}`,
          choice_imgUrl: "",
        });
      }

      setPropositionFrm((prev) => {
        return [...prev, ...newArr];
      });
    }
  };

  useEffect(() => {
    // console.log(propositionFrm);
  }, [propositionFrm]);

  const handleSubjectChange = (event, value) => {
    console.log("value?.id", value?.id);
    setSubjectId(value?.id);
    validateStep1({ subjectId: value?.id });
  };

  const handleLevelChange = (value) => {
    console.log("value?.id", value);
    setLevel(value);
    validateStep1({ level: value });
  };

  const handleJobChange = (event, value) => {
    setJobPositionId(value?.id);
    validateStep1({ jobPositionId: value?.id });
  };

  const handleRandomChange = (value) => {
    setIsRandom(!value);
  };

  const handleTopicNameChange = (value) => {
    setTopicName(value);
    validateStep1({ topicName: value });
  };

  const handleExamOwnerChange = (value) => {
    setExamOwner(value);
    validateStep1({ examOwner: value });
  };

  const handleAmountAnswerChange = (value) => {
    setAmountAnswer(value);
    validateStep1({ amountAnswer: value });
  };

  const submitCreateExam = (event) => {
    event.preventDefault();

    if (validateStep2()) {
      const images = propositionFrm?.map((it) => ({
        choice_no: it.choice_no,
        image_url: it.choice_imgUrl,
        image_order: 1,
      }));

      let obj = {
        subject_id: +subjectId,
        topic_name: topicName,
        exam_owner: examOwner,
        level: +level,
        amount_answer: +amountAnswer,
        job_position_id: +jobPositionId,
        is_random_answer: isRandom,
        company_id: 1,
        choices: propositionFrm,
        images,
      };
      dispatch(addExamQuestion(obj)).then(() => onClose());
      console.log(obj);
    }
  };

  const handleCheckboxChange = (value, index) => {
    let newArr = [...propositionFrm];
    newArr[index].is_answer = !value;
    setPropositionFrm(newArr);
  };

  const setUploadImgUrl = (obj) => {
    let newArr = [...propositionFrm];
    newArr[obj?.index].choice_imgUrl = obj?.path;
    setPropositionFrm(newArr);
  };

  useEffect(() => {
    if (indexImgUrl) {
      setUploadImgUrl(indexImgUrl);
    }
  }, [indexImgUrl]);

  // const filterOptions = createFilterOptions({
  //   matchFrom: "start",
  //   stringify: (option: FilmOptionType) => option.label,
  // });

  const filterOpt = (options, state) => {
    let result = options.filter((option) => {
      return option.label
        .replace(/\s/g, "")
        .toLowerCase()
        .includes(state.inputValue.replace(/\s/g, "").toLowerCase());
    });
    return result;
  };

  const handleSetDelta = (value, index) => {
    let newArr = [...propositionFrm];
    newArr[index].choice_description = JSON.stringify(value);
    setPropositionFrm(newArr);
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">สร้างข้อสอบใหม่</div>
          <div className="stepbar-container">
            <StepBar steps={steps} activeStep={currentStep} />
          </div>
        </div>
        <br />
        {currentStep === 0 ? (
          <form onSubmit={nexStep}>
            <div className="form-wrapper">
              <div className="section-group">
                <div className="group-title">รายละเอียดเกี่ยวกับข้อสอบ</div>
                <div className="group-field">
                  <Autocomplete
                    disablePortal
                    options={dataSubject}
                    sx={{ width: 300 }}
                    onChange={handleSubjectChange}
                    errorText={errors.subjectId}
                    isError={errors.subjectId}
                    id="field-subject"
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <span>{option.label}</span>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="วิชา" />
                    )}
                  />
                  <TextField
                    value={topicName}
                    onChange={(e) => handleTopicNameChange(e.target.value)}
                    label="หัวข้อสอบ"
                    error={errors.topicName}
                    id="field-topicname"
                  />
                </div>
                <div className="group-field">
                  <TextField
                    value={examOwner}
                    onChange={(e) => handleExamOwnerChange(e.target.value)}
                    label="กรอกชื่อผู้ออกข้อสอบ"
                    error={errors.examOwner}
                    id="field-examowner"
                  />
                </div>
              </div>
              <div className="section-group">
                <div className="group-title">ข้อมูลเกี่ยวกับโจทย์</div>
                <div className="group-field">
                  <Select
                    label="ระดับความยาก"
                    items={difficultyLevelList}
                    value={level}
                    onChange={(e) => handleLevelChange(e.target.value)}
                    defaultValue="ระดับความยาก"
                    className="field-subject"
                    errorText={errors.level}
                    isError={errors.level}
                    id="field-level"
                  />
                  <TextField
                    label="จำนวนคำตอบ"
                    value={amountAnswer}
                    onChange={(e) => handleAmountAnswerChange(e.target.value)}
                    className="field-amountanswer"
                    error={errors.amountAnswer}
                    id="field-amountanswer"
                  />
                </div>
                <div className="group-field">
                  <Autocomplete
                    disablePortal
                    options={dataJobs}
                    sx={{ width: 300 }}
                    onChange={handleJobChange}
                    errorText={errors.jobPositionId}
                    isError={errors.jobPositionId}
                    id="field-job"
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <span>{option.label}</span>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="งานตำแหน่ง" />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="button-wrapper">
              <Button onClick={onClose} outlined>
                ยกเลิก{" "}
              </Button>
              <ButtonBlack id="btn-submit-first-frm" type="submit">
                ถัดไป
                <ArrowForwardIcon />
              </ButtonBlack>
            </div>
          </form>
        ) : (
          <form onSubmit={submitCreateExam}>
            <div className="form-wrapper">
              {propositionFrm?.map((item, index) => (
                <div className="section-group" key={item.choice_no}>
                  <div className="answer-container">
                    <div className="group-title">{item.titleText}</div>
                    {index !== 0 && (
                      <div className="answer-item">
                        <Checkbox
                          id={`checkbox-answer-${index}`}
                          {...label}
                          onChange={(e) =>
                            handleCheckboxChange(item.is_answer, index)
                          }
                        />{" "}
                        เลือกเป็นคำตอบที่ถูกต้อง
                      </div>
                    )}
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <RichTextEditor
                        onRichTextBlur={(e) => handleSetDelta(e, index)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <UploadImage
                        minWidth={"290px"}
                        inputID={`answer${index}_img_url`}
                        index={index}
                        setURLImage={setImgUrl}
                        setIndexImage={setIndexImgUrl}
                        defaultImage={item?.choice_imgUrl}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}
              <div className="section-group">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    หมายเหตุ : การแก้ไขข้อสอบ จะต้องอนุมัติข้อสอบใหม่อีกครั้ง
                  </Grid>
                  <Grid item xs={6}>
                    <div className="text-right">
                      ต้องการ Random คำตอบหรือไม่{" "}
                      <Switch
                        {...label}
                        checked={isRandom}
                        onChange={() => {
                          handleRandomChange(isRandom);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="button-wrapper">
              <Button onClick={onClose} outlined>
                ยกเลิก{" "}
              </Button>
              <ButtonBlack type="submit">บันทึก</ButtonBlack>
            </div>
          </form>
        )}
      </Div>
    </BaseModal>
  );
};

export default PopupManageExamQuestion;
