import { GET_PRE_ASSESSMENT_LIST } from "redux/actionTypes";
import {} from "utils/common";

const mapItemList = (itemList) => {
  const newList = itemList.map((item, index) => ({
    id: index + 1,
    ...item,
  }));
  return newList;
};

const initialState = {
  total: 0,
  limit: 10,
  skip: 0,
  preAssessmentList: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PRE_ASSESSMENT_LIST.SUCCESS: {
      const { total, limit, skip, data } = action.payload;

      return {
        ...state,
        total,
        limit,
        skip,
        preAssessmentList: mapItemList(data),
      };
    }

    default:
      return state;
  }
};
