import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";

import { addMember } from "redux/actions/member";

import {
  selectMasterDataProvince,
  selectMasterDataDistrict,
  selectMasterDataSubDistrict,
  selectMasterDataBank,
  selectMasterDataNationality,
} from "../../redux/selectors";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    /* background: #f7f9fa;
    border-radius: 50%; */

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const PopupaddMember = ({
  open,
  onClose,
  label,
  dataEdit = {},
  isUploading,
  maxWidth,
  fileName,
  percentage,
  error,
}) => {
  const dispatch = useDispatch();
  const masterDataProvince = useSelector(selectMasterDataProvince);
  const masterDataDistrict = useSelector(selectMasterDataDistrict);
  const masterDataSubDistrict = useSelector(selectMasterDataSubDistrict);
  const masterDataBank = useSelector(selectMasterDataBank);
  const masterDataNationality = useSelector(selectMasterDataNationality);

  const [dataProvince, setDataProvince] = useState(masterDataProvince);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubdistrict, setDataSubDistrict] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nationality, setNationality] = useState("");
  const [idCardNumber, setIdCardNumber] = useState("");

  const [address, setAddress] = useState("");
  const [road, setRoad] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [subdistrict, setSubdistrict] = useState("");
  const [postcode, setPostcode] = useState("");

  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");

  const [idCardImgUrl, setIdCardImgUrl] = useState("");
  const [idBankAccountImgUrl, setIdBankAccountImgUrl] = useState("");

  const [errors, setErrors] = useState({});

  const handleProvinceChange = (value) => {
    const districts = masterDataDistrict.filter(
      (d) => d.province_id === +value
    );
    setDataDistrict(districts);
    setProvince(value);
    validate({ province: value });
  };
  const handleDistrictChange = (value) => {
    const subDistricts = masterDataSubDistrict.filter(
      (s) => s.district_id === +value
    );
    setDataSubDistrict(subDistricts);
    setDistrict(value);
    validate({ district: value });
  };
  const handleSubDistrictChange = (value) => {
    setSubdistrict(value);
    handlePostcodeChange(value);
    validate({ subdistrict: value });
  };

  const handlePostcodeChange = (value) => {
    const postcode = masterDataSubDistrict
      .filter((s) => s.value === +value)
      .map((z) => z.postcode)[0];
    setPostcode(postcode);
    validate({ postcode: value });
  };

  const submitAddMember = (event) => {
    event.preventDefault();
   
    if (validate()) {
      const obj = {
        id_card: idCardNumber,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        nationality: nationality || "ไทย",
        bank_account: bankName, // bank_name
        bank_account_no: bankAccountNumber,
        bank_account_name: bankAccountName,
        id_card_img_url: idCardImgUrl,
        bank_account_img_url: idBankAccountImgUrl,
        member_type: 1,
        address: [
          {
            address_type: 1,
            address: address,
            road: road,
            province_id: province,
            district_id: district,
            sub_district_id: subdistrict,
            zip_code: postcode,
          },
        ],
      };
      dispatch(addMember(obj));
      onClose();
    }
  };

  const validate = (
    fieldValues = {
      firstName,
      lastName,
      email,
      phone,
      nationality,
      idCardNumber,
      address,
      road,
      province,
      district,
      subdistrict,
      postcode,
      bankName,
      bankAccountNumber,
      bankAccountName,
    }
  ) => {
    let temp = { ...errors };
    if ("firstName" in fieldValues)
      temp.firstName = fieldValues.firstName !== "" ? "" : "กรุณากรอกชื่อ";
    if ("lastName" in fieldValues)
      temp.lastName = fieldValues.lastName !== "" ? "" : "กรุณากรอกนามสกุล";
    if ("email" in fieldValues)
      temp.email = fieldValues.email !== "" ? "" : "กรุณากรอก Email";
    if ("phone" in fieldValues)
      temp.phone = fieldValues.phone !== "" ? "" : "กรุณากรอกเบอร์โทร";
    // if ("nationality" in fieldValues)
    //   temp.nationality =
    //     fieldValues.nationality !== "" ? "" : "กรุณาเลือกสัญชาติ";
    if ("idCardNumber" in fieldValues)
      temp.idCardNumber =
        fieldValues.idCardNumber !== "" ? "" : "กรุณากรอกเลขบัตรประชาชน";
    if ("address" in fieldValues)
      temp.address =
        fieldValues.address !== "" ? "" : "กรุณากรอกที่อยู่ปัจจุบัน";
    if ("road" in fieldValues)
      temp.road = fieldValues.road !== "" ? "" : "กรุณาเลือกถนน";
    if ("province" in fieldValues)
      temp.province = fieldValues.province !== "" ? "" : "กรุณาเลือกจังหวัด";
    if ("district" in fieldValues)
      temp.district = fieldValues.district !== "" ? "" : "กรุณาเลือกอำเภอ";
    if ("subdistrict" in fieldValues)
      temp.subdistrict = fieldValues.subdistrict !== "" ? "" : "กรุณาเลือกตำบล";
    if ("postcode" in fieldValues)
      temp.postcode =
        fieldValues.postcode !== "" ? "" : "กรุณากรอกรหัสไปรษณีย์";
    if ("bankName" in fieldValues)
      temp.bankName = fieldValues.bankName !== "" ? "" : "กรุณาเลือกธนาคาร";
    if ("bankAccountNumber" in fieldValues)
      temp.bankAccountNumber =
        fieldValues.bankAccountNumber !== ""
          ? ""
          : "กรุณากรอกเลขที่บัญชีธนาคาร";
    if ("bankAccountName" in fieldValues)
      temp.bankAccountName =
        fieldValues.bankAccountName !== "" ? "" : "กรุณากรอกชื่อบัญชี";

    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {}, []);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <form onSubmit={submitAddMember}>
          <div className="form-wrapper">
            <div className="title">Add Individual Member</div>

            <p className="form-topic">ข้อมูล</p>
            <InlineSpaceText label="ชื่อจริง">
              <TextField
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  validate({ ["firstName"]: e.target.value });
                }}
                className="field-firstName"
                error={errors.firstName}
              />
            </InlineSpaceText>
            <InlineSpaceText label="นามสกุล">
              <TextField
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  validate({ ["lastName"]: e.target.value });
                }}
                className="field-lastName"
                error={errors.lastName}
              />
            </InlineSpaceText>
            <InlineSpaceText label="Email">
              <TextField
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validate({ ["email"]: e.target.value });
                }}
                className="field-email"
                error={errors.email}
              />
            </InlineSpaceText>
            <InlineSpaceText label="เบอร์โทร">
              <TextField
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  validate({ ["phone"]: e.target.value });
                }}
                className="field-phone"
                error={errors.phone}
              />
            </InlineSpaceText>
            <InlineSpaceText label="สัญชาติ">
              <Select
                items={masterDataNationality}
                value={nationality}
                onChange={(e) => {
                  setNationality(e.target.value);
                  // validate({ ["nationality"]: e.target.value });
                }}
                defaultValue="เลือกสัญชาติ"
                className="field-nationality"
                id="field-nationality"
                // errorText={errors.nationality}
                // isError={errors.nationality}
              />
            </InlineSpaceText>
            <InlineSpaceText label="รหัสบัตรประชาชน">
              <TextField
                value={idCardNumber}
                onChange={(e) => {
                  setIdCardNumber(e.target.value);
                  validate({ ["idCardNumber"]: e.target.value });
                }}
                className="field-idCardNumber"
                error={errors.idCardNumber}
              />
            </InlineSpaceText>

            <p className="form-topic">ที่อยู่</p>
            <InlineSpaceText label="ที่อยู่ปัจุบัน">
              <TextField
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  validate({ ["address"]: e.target.value });
                }}
                className="field-address"
                error={errors.address}
              />
            </InlineSpaceText>
            <InlineSpaceText label="ถนน">
              <TextField
                value={road}
                onChange={(e) => {
                  setRoad(e.target.value);
                  validate({ ["road"]: e.target.value });
                }}
                className="field-road"
                error={errors.road}
              />
            </InlineSpaceText>
            <InlineSpaceText label="จังหวัด">
              <Select
                items={dataProvince}
                value={province}
                onChange={(e) => handleProvinceChange(e.target.value)}
                defaultValue="เลือกจังหวัด"
                className="field-province"
                id="address-province"
                errorText={errors.province}
                isError={errors.province}
              />
            </InlineSpaceText>
            <InlineSpaceText label="อำเภอ">
              <Select
                items={dataDistrict}
                value={district}
                onChange={(e) => handleDistrictChange(e.target.value)}
                defaultValue="เลือกอำเภอ"
                className="field-district"
                id="address-district"
                errorText={errors.district}
                isError={errors.district}
              />
            </InlineSpaceText>
            <InlineSpaceText label="ตำบล">
              <Select
                items={dataSubdistrict}
                value={subdistrict}
                onChange={(e) => handleSubDistrictChange(e.target.value)}
                defaultValue="เลือกตำบล"
                className="field-subdistrict"
                id="address-subdistrict"
                errorText={errors.subdistrict}
                isError={errors.subdistrict}
              />
            </InlineSpaceText>
            <InlineSpaceText label="รหัสไปรษณีย์">
              <TextField
                value={postcode}
                onChange={(e) => {
                  handlePostcodeChange(e.target.value);
                  validate({ ["postcode"]: e.target.value });
                }}
                className="field-postcode"
                error={errors.postcode}
              />
            </InlineSpaceText>

            {/* <p className="form-topic">ธนาคาร</p>
            <InlineSpaceText label="ชื่อธนาคาร">
              <Select
                items={masterDataBank}
                value={bankName}
                onChange={(e) => {
                  setBankName(e.target.value);
                  validate({ ["bankName"]: e.target.value });
                }}
                className="field-bankName"
                id="field-bankName"
                defaultValue="เลือกธนาคาร"
                errorText={errors.bankName}
                isError={errors.bankName}
              />
            </InlineSpaceText>
            <InlineSpaceText label="เลขที่บัญชีธนาคาร">
              <TextField
                value={bankAccountNumber}
                onChange={(e) => {
                  setBankAccountNumber(e.target.value);
                  validate({ ["bankAccountNumber"]: e.target.value });
                }}
                className="field-bankAccountNumber"
                error={errors.bankAccountNumber}
              />
            </InlineSpaceText>
            <InlineSpaceText label="ชื่อบัญชี">
              <TextField
                value={bankAccountName}
                onChange={(e) => {
                  setBankAccountName(e.target.value);
                  validate({ ["bankAccountName"]: e.target.value });
                }}
                className="field-bankAccountName"
                id="field-bankAccountName"
                error={errors.bankAccountName}
              />
            </InlineSpaceText>
            <UploadImage
              label={"อัพโหลดรูปบัตรประชาชน"}
              inputID={"id_card_img_url"}
              setURLImage={setIdCardImgUrl}
            />
            <UploadImage
              inputID={"bank_account_img_url"}
              label={"อัพโหลดรูปสมุดบัญชีธนาคาร"}
              setURLImage={setIdBankAccountImgUrl}
            /> */}
          </div>

          <div className="button-wrapper">
            <Button onClick={onClose} outlined>
              Cancel{" "}
            </Button>
            <ButtonBlack type="submit">Add</ButtonBlack>
          </div>
        </form>{" "}
      </Div>
    </BaseModal>
  );
};

export default PopupaddMember;
