import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Table from "components/table/Table";

import Grid from "@mui/material/Grid";

import { getAllQuestionActive } from "redux/actions/library";
import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";
import ButtonIconDeleteCircle from "components/form/ButtonIconDeleteCircle";
import ButtonIconAddCircle from "components/form/ButtonIconAddCircle";

import ReactHtmlParser from "react-html-parser";
import DeltaToHtml from "components/form/DeltaToHtml";

const Div = styled.div`
  padding: 20px;
  width: 1200px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .mb-3 {
    margin-bottom: 18px;
  }

  .level_container {
    display: flex;
    margin-bottom: 5px;

    .level_selection {
      margin-right: 5px;
    }
    .level_field {
      margin-right: 5px;
    }
    .level_btn {
      margin-top: 7px;
    }
  }

  .right {
    text-align: right;
  }

  .truncate {
    h1,
    h2,
    h3,
    h4,
    p {
      white-space: nowrap;
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const ConvertHtmlToString = ({ value }) => {
  if (value === "") {
    return "-";
  }
  let isDeltaString = value?.startsWith("[");
  if (isDeltaString)
    return <div className="truncate">{<DeltaToHtml contents={value} />}</div>;
  return <div className="truncate">{ReactHtmlParser(value)}</div>;
};

const columns = [
  {
    field: "id",
    headerName: "ลำดับที่",
    width: 250,
  },
  {
    field: "code",
    headerName: "รหัสข้อสอบ",
    width: 300,
  },
  {
    field: "question",
    headerName: "โจทย์",
    width: 500,
    renderCell: ConvertHtmlToString,
  },
  { field: "nameUpper", headerName: "หมวดวิชา", width: 250 },
  { field: "topicName", headerName: "หัวข้อ", width: 250 },
  {
    field: "level",
    headerName: "ระดับความยาก",
    width: 250,
  },
  {
    field: "examOwner",
    headerName: "ชื่อผู้ออกข้อสอบ",
    width: 250,
  },
  {
    field: "createdAt",
    headerName: "วันที่สร้างข้อสอบ",
    width: 250,
  },
  {
    field: "status",
    headerName: "การเปิดใช้งาน",
    width: 250,
  },
];

const PopupAddQuestion = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
  subject,
  setDataRandomExams,
  dataRandomExams,
}) => {
  const dispatch = useDispatch();
  const { questions, total, limit } = useSelector((state) => state.library);

  const initFilter = {
    code: "",
    examOwner: "",
    subjectId: +subject,
    topicName: "",
    level: "",
    question: "",
  };

  const masterDataSubject = useSelector(selectMasterSubject);
  const masterDataJob = useSelector(selectMasterJob);
  const [dataSubject, setDataSubject] = useState(masterDataSubject);
  const [dataJob, setDataJob] = useState(masterDataJob);

  const [questionList, setQuestionList] = useState(questions);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [questionArr, setQuestionArr] = useState([]);

  const [subjectId, setSubjectId] = useState("");
  const [topicName, setTopicName] = useState("");
  const [jobPositionId, setJobPositionId] = useState("");
  const [totalQuestion, setTotalQuestion] = useState("");
  const [levelList, setLevelList] = useState([{ level: "", totalQuestion: 0 }]);

  const handleSearch = () => {
    setPage(0);
    dispatch(getAllQuestionActive(filter));
  };

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setFilter({ ...filter, subjectId: +subject });
  }, [subject]);

  useEffect(() => {
    setQuestionList(questions);
  }, [questions]);

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getAllQuestionActive(filter, nextPage * limit));
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getAllQuestionActive(initFilter));
  };

  const selectQuestion = (idList) => {
    if (idList.length === 0) return;
    let newArr = [...dataRandomExams];
    for (const id of idList) {
      let isProductExist = newArr.some((it) => it.id === id);
      if (!isProductExist) {
        let item = questionList.find((it) => it.id === id);
        newArr.push(item);
      }
    }
    setQuestionArr(newArr);
  };

  const handleSubmitAddQuestion = () => {
    setDataRandomExams(questionArr);
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">เพิ่มข้อสอบเข้าสู่ชุดข้อสอบ</div>
            <div className="content-wrapper">
              <div className="form-wrapper">
                <div className="section-group">
                  <div className="group-field">
                    <TextField
                      label="รหัสข้อสอบ"
                      value={filter.code}
                      name="search_code"
                      onChange={(e) =>
                        handleFilterChange("code", e.target.value)
                      }
                    />
                    <TextField
                      label="โจทย์"
                      value={filter.question}
                      name="search_question"
                      onChange={(e) =>
                        handleFilterChange("question", e.target.value)
                      }
                    />
                    <TextField
                      label="ชื่อผู้ออกข้อสอบ"
                      value={filter.examOwner}
                      name="search_owner"
                      onChange={(e) =>
                        handleFilterChange("examOwner", e.target.value)
                      }
                    />
                    <TextField
                      label="หัวข้อ"
                      value={filter.topicName}
                      name="search_topicName"
                      onChange={(e) =>
                        handleFilterChange("topicName", e.target.value)
                      }
                    />
                    <TextField
                      label="ระดับความยาก"
                      value={filter.level}
                      name="search_level"
                      onChange={(e) =>
                        handleFilterChange("level", e.target.value)
                      }
                    />
                  </div>
                </div>
                <br />
                <div>
                  <div className="section-group ">
                    <div className="group-field end">
                      <Button onClick={() => handleSearch()}>Apply</Button>
                      <Button onClick={() => handleClearFilter()} outlined>
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-wrapper">
                <Table
                  columns={columns}
                  rows={questionList}
                  checkboxSelection={true}
                  rowCount={total}
                  pageSize={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onSelectionChange={(id) => selectQuestion(id)}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack onClick={() => handleSubmitAddQuestion()}>
            ยืนยัน
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupAddQuestion;
