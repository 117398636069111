import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";

import { getPreAssessmentList } from "redux/actions/preAssessment";
import PopupComfirmDeleteCampaign from "containers/popup/PopupComfirmDeleteCampaign";
import formatValue from "utils/formatValue";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 220px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const initFilter = {
  campaign_name: "",
  report_id: "",
  name: "",
  phone: "",
  email: "",
  gender: "",
};

const mockData = [
  {
    id: 1,
    campaign_name: "แบบทดสอบ A",
    campaign_id: "100001",
    first_name: "สมพงษ์",
    last_name: "ประเสริฐ",
    birthdate: "5 ม.ค. 2020",
    gender: "ชาย",
    phone: "081-111-1111",
    link: "https://www.youtube.com/watch?v=aHYiO8Y5H3Y&list=RDuDfjZWUJ2P0&index=22",
    created_at: "2022-11-07 13:50:42",
    deleteDate: "1/1/2022",
  },
];

const statusList = [
  { text: "Active", value: "active" },
  { text: "Inactive", value: "inactive" },
];

const PreAssessmentAll = () => {
  const dispatch = useDispatch();
  const { preAssessmentList, total, limit } = useSelector(
    (state) => state.preAssessment
  );

  const [dataTable, setDataTable] = useState(mockData);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  const [campaignDetail, setCampaignDetail] = useState();

  const [
    isOpenPopupComfirmDeleteCampaign,
    setIsOpenPopupComfirmDeleteCampaign,
  ] = useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setDataTable(preAssessmentList);
  }, [preAssessmentList]);

  const handleSearch = () => {
    setPage(0);
    dispatch(getPreAssessmentList(filter));
    console.log("filter", filter);
  };

  const columns = [
    {
      field: "id",
      headerName: "No.",
    },
    {
      field: "campaign_name",
      headerName: "Assessment Name",
      width: 240,
    },
    {
      field: "campaign_id",
      headerName: "Report ID",
      width: 200,
    },
    {
      field: "first_name",
      headerName: "ชื่อจริง",
      width: 200,
    },
    {
      field: "last_name",
      headerName: "นามสกุล",
      width: 200,
    },
    {
      field: "birthdate",
      headerName: "วันเกิด",
      width: 250,
      renderCell: ({ value }) => formatValue("Date2", value),
    },
    {
      field: "gender",
      headerName: "เพศ",
      width: 100,
    },
    {
      field: "phone",
      width: 200,
      headerName: "เบอร์โทร",
    },
    {
      field: "report_link",
      headerName: "Report Link",
      width: 200,
      renderCell: ({ value }) => (
        <Button onClick={() => copyToClipBoard(`${value}`)}>Copy Link </Button>
      ),
    },
    {
      field: "created_at",
      headerName: "Create Date",
      width: 200,
      renderCell: ({ value }) => formatValue("DateThai", value),
    },
    {
      field: "date_report_delete",
      headerName: "วันที่ลบ Report",
      width: 200,
      renderCell: ({ value }) => formatValue("Date2", value),
    },
    { field: "status", headerName: "Status", width: 200 },
  ];

  const handleFilterChange = (key, value) => {
    console.log(value);
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getPreAssessmentList(initFilter));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getPreAssessmentList(filter, nextPage * limit));
  };

  const handleDeleteRow = (row) => {
    setCampaignDetail(row);
    setIsOpenPopupComfirmDeleteCampaign(true);
  };

  const handleCloseDeleteRow = () => {
    setIsOpenPopupComfirmDeleteCampaign(false);
    handleSearch();
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      alert("คัดลอกลิงก์รายงานแล้ว");
    } catch (err) {
      alert("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
    }
  };

  return (
    <SidebarLayout title="Report">
      {Boolean(isOpenPopupComfirmDeleteCampaign) && (
        <PopupComfirmDeleteCampaign
          campaignDetail={campaignDetail}
          open
          onClose={handleCloseDeleteRow}
        />
      )}
      <Div>
        <SubMenu
          menu={[{ path: "/pre-assessment/all", text: "Pre Assessment" }]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Filter</div>
              <div className="group-field">
                <Select
                  items={statusList}
                  value={filter.gender}
                  defaultValue="เพศ"
                  className="field-status"
                  onChange={(e) => handleFilterChange("gender", e.target.value)}
                />
              </div>
            </div>
            <div className="section-group">
              <div className="group-title">Search Report</div>
              <div className="group-field">
                <TextField
                  value={filter.campaign_name}
                  name="campaign_name"
                  placeholder="Assessment Name"
                  type="search"
                  onChange={(e) =>
                    handleFilterChange("campaign_name", e.target.value)
                  }
                />
                <TextField
                  value={filter.report_id}
                  name="report_id"
                  placeholder="Report ID"
                  onChange={(e) =>
                    handleFilterChange("report_id", e.target.value)
                  }
                />
                <TextField
                  value={filter.name}
                  name="name"
                  placeholder="ชื่อ-นามสกุล"
                  onChange={(e) => handleFilterChange("name", e.target.value)}
                />
                <TextField
                  value={filter.phone}
                  name="phone"
                  placeholder="เบอร์โทร"
                  onChange={(e) => handleFilterChange("phone", e.target.value)}
                />
                <TextField
                  value={filter.email}
                  name="email"
                  placeholder="Email"
                  onChange={(e) => handleFilterChange("email", e.target.value)}
                />
              </div>
            </div>
            <div className="section-group ">
              <div className="group-field end">
                <Button onClick={() => handleSearch()}>Apply</Button>
                <Button onClick={() => handleClearFilter()} outlined>
                  Clear
                </Button>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={dataTable}
              rowCount={total}
              pageSize={+limit}
              page={page}
              disableSelectionOnClick={true}
              checkboxSelection={false}
              onPageChange={handlePageChange}
              onDeleteRow={handleDeleteRow}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default PreAssessmentAll;
