import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Select from "components/form/Select";
import { Grid } from "@material-ui/core";

import TextField from "components/form/TextField";

import TextFieldArea from "components/form/TextFieldArea";
import InlineSpaceText from "components/form/InlineSpaceText";
import Table from "components/table/Table";

import { ArrowBack as BackIcon } from "@material-ui/icons";
import ReactHtmlParser from "react-html-parser";

import { selectMasterSubject, selectMasterJob } from "redux/selectors";
import {
  createExamSet,
  getExamSetById,
  updateExamSet,
  clearStateExamSet,
} from "redux/actions/library";
import PopupRandomExamADT from "containers/popup/PopupRandomExamADT";
import PopupRandomExamShuffleOrNormal from "containers/popup/PopupRandomExamShuffleOrNormal";
import PopupAddQuestion from "containers/popup/PopupAddQuestion";
import SidebarLayout from "components/layout/SidebarLayout";
import PopupOrderingExamSet from "containers/popup/PopupOrderingExamSet";
import PopupClientLibrary from "containers/popup/PopupClientLibrary";
import DeltaToHtml from "components/form/DeltaToHtml";

import Autocomplete from "@mui/material/Autocomplete";
import { xor } from "lodash";

const Div = styled.div`
  padding: 20px;

  .stepbar-container {
    padding: 0 250px;
    margin-top: 10px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }

  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
        padding: 6px;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        align-items: start;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
          margin-right: 16px;
        }

        > * {
          width: 250px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
        }
      }
    }

    .text-right {
      text-align: right;
    }

    .invilid {
      color: red;
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .answer-container {
    display: flex;
    .answer-item {
    }
  }

  .score-container {
    margin-top: 15px;
  }

  .summary-score-container {
    display: flex;
    width: 100%;
    align-items: center;

    .summary-score-title {
      width: 33%;
    }

    .summary-score-box {
      margin: 5px 0;
      display: flex;
      align-items: center;

      .header {
        font-size: 14px;
        font-weight: bold;
        width: 55%;
        margin-right: 8px;
      }
      .score {
        width: 46%;
        border-right: 1px solid black;
        padding-right: 8px;
        margin-right: 8px;
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .button-table {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
    margin-bottom: 10px;
    .btn {
      margin-right: 15px;
    }
  }

  .header-title {
    display: flex;
    justify-content: space-between;
  }

  .btn_back {
    margin-right: 20px;
    cursor: pointer;
  }

  .truncate {
    h1,
    h2,
    h3,
    h4,
    p {
      white-space: nowrap;
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiAutocomplete-root {
    width: 100%;
  }
`;

const modList = [
  { value: "adt", text: "ADT" },
  { value: "shuffle", text: "SHUFFLE" },
  { value: "normal", text: "NORMAL" },
];

const scoreList = [
  { value: -5, text: "-5" },
  { value: -4, text: "-4" },
  { value: -3, text: "-3" },
  { value: -2, text: "-2" },
  { value: -1, text: "-1" },
  { value: 0, text: "0" },
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
];

const difficultyLevelList = [
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
  { value: 5, text: 5 },
  { value: 6, text: 6 },
  { value: 7, text: 7 },
  { value: 8, text: 8 },
  { value: 9, text: 9 },
  { value: 10, text: 10 },
  { value: 11, text: 11 },
];

const resultMappingInit = [
  {
    name: "ดี",
    min_score: 0,
    max_score: 0,
    description: "คุณมีความสามารถในระดับดี",
  },
  {
    name: "ปานกลาง",
    min_score: 0,
    max_score: 0,
    description: "คุณมีความสามารถในระดับปานกลาง",
  },
  {
    name: "แย่",
    min_score: 0,
    max_score: 0,
    description: "คุณมีความสามารถในระดับแย่",
  },
];

const ExamSeriesCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { type, id } = useParams();

  const { examSetDetail, randomExams } = useSelector((state) => state.library);

  const masterDataSubject = useSelector(selectMasterSubject);
  const [dataSubject, setDataSubject] = useState(masterDataSubject);

  const [data, setData] = useState({
    rows: [],
    totalRows: 0,
    pageSize: 10,
    page: 0,
  });

  const [examMode, setExamMode] = useState("adt");

  const [isOpenRandomADTPopup, setIsOpenRandomADTPopup] = useState(false);
  const [
    isOpenRandomShuffleOrNormalPopup,
    setIsOpenRandomShuffleOrNormalPopup,
  ] = useState(false);
  const [isOpenPopupAddQuestion, setIsOpenPopupAddQuestion] = useState(false);
  const [isOpenPopupOrderingExamSet, setIsOpenPopupOrderingExamSet] =
    useState(false);
  const [isOpenPopupClientLibrary, setIsOpenPopupClientLibrary] =
    useState(false);

  const [topicName, setTopicName] = useState("");

  const [dataRandomExams, setDataRandomExams] = useState([]);
  const [dataRandomExamsTime, setDataRandomExamsTime] = useState([]);

  const [countLevel, setCountLevel] = useState([]);
  const [clientLibrary, setClientLibrary] = useState([]);
  const [summaryExamTime, setSummaryExamTime] = useState(0);

  const [name, setName] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [amountQuestion, setAmountQuestion] = useState("");
  const [author, setAuthor] = useState("");
  const [companyId, setCompanyId] = useState(1);
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("ไทย");
  const [level, setLevel] = useState("");

  const [resultMapping, setResultMapping] = useState(resultMappingInit);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let subjectArr = masterDataSubject.map((it) => ({
      label: it.text,
      id: it.value,
    }));
    setDataSubject(subjectArr);
  }, [masterDataSubject]);

  const validate = () => {
    let temp = {};
    temp.name = name !== "" ? "" : "กรุณากรอกชื่อชุดข้อสอบ";
    temp.author = author !== "" ? "" : "กรุณากรอก Author";
    temp.subject = subjectId !== "" ? "" : "กรุณาเลือกวิชา";
    temp.amountQuestion = amountQuestion !== "" ? "" : "กรุณากรอกจำนวนข้อ";
    temp.level = level !== "" ? "" : "กรุณาเลือก Level";
    temp.description = description !== "" ? "" : "กรุณากรอก description";

    const resultmapping = resultMapping?.map((value) => ({
      description: value.description !== "" ? "" : "กรุณากรอก description",
      min_score: value.min_score >= 0 ? "" : "กรุณากรอก Max score",
      max_score: value.max_score >= 0 ? "" : "กรุณากรอก Min Score",
    }));

    temp.resultmapping = resultmapping;
    temp.summaryExamTime =
      summaryExamTime !== 0 ? "" : "กรุณากรอกเวลารวมในการทำข้อสอบ";

    setErrors({ ...temp });

    return (
      temp.name === "" &&
      temp.author === "" &&
      temp.subject === "" &&
      temp.amountQuestion === "" &&
      temp.level === "" &&
      temp.description === "" &&
      temp.summaryExamTime === "" &&
      temp.resultmapping?.every(
        (it) =>
          it?.description === "" && it?.max_score === "" && it?.min_score === ""
      )
    );
  };

  const handleMyLevelChange = (value, id) => {
    let newArr = [...dataRandomExams];
    console.log("handleMyLevelChange", newArr);
    let indexArr = newArr.findIndex((it) => it.id === id);
    newArr[indexArr].mylevel = +value;
    setDataRandomExams(newArr);
  };

  const MyLevelSelection = ({ row }) => {
    return (
      <Select
        items={difficultyLevelList}
        value={row?.mylevel || row?.level}
        defaultValue="Level"
        className="field_level"
        onChange={(e) => handleMyLevelChange(e.target.value, row?.id)}
      />
    );
  };

  const ConvertHtmlToString = ({ value }) => {
    if (value === "") {
      return "-";
    }
    let isDeltaString = value?.startsWith("[");
    if (isDeltaString)
      return <div className="truncate">{<DeltaToHtml contents={value} />}</div>;
    return <div className="truncate">{ReactHtmlParser(value)}</div>;
  };

  const columns = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 250,
    },
    {
      field: "code",
      headerName: "รหัสข้อสอบ",
      width: 300,
    },
    {
      field: "question",
      headerName: "โจทย์",
      width: 500,
      renderCell: ConvertHtmlToString,
    },
    {
      field: "nameUpper",
      headerName: "หมวดวิชา",
      width: 250,
    },
    {
      field: "topicName",
      headerName: "หัวข้อ",
      width: 250,
    },
    {
      field: "level",
      headerName: "Center Level",
      width: 250,
    },
    {
      field: "-",
      headerName: "My Level",
      width: 250,
      renderCell: MyLevelSelection,
    },
    {
      field: "examOwner",
      headerName: "ชื่อผู้ออกข้อสอบ",
      width: 250,
    },
    {
      field: "createdAt",
      headerName: "วันที่สร้างข้อสอบ",
      width: 250,
    },
  ];

  const columnsClient = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 80,
    },
    {
      field: "code",
      headerName: "ชื่อ",
      width: 200,
    },
    {
      field: "question",
      headerName: "Email",
      width: 200,
    },
    {
      field: "nameUpper",
      headerName: "เบอร์โทร",
      width: 200,
    },
  ];

  const countQuestionLevel = (newArr) => {
    // if (countLevel?.length === 0) {
    var counts = newArr.reduce((feild, item) => {
      var name = item?.mylevel || item?.level;
      if (!feild.hasOwnProperty(name)) {
        feild[name] = 0;
      }
      feild[name]++;
      return feild;
    }, {});

    var countsExtended = Object.keys(counts).map((k) => {
      let countArr = [];
      if (examSetDetail?.examSetSummary?.length > 0) {
        countArr = examSetDetail?.examSetSummary?.filter(
          (c) => +c.exam_level === +k
        );
        console.log();
      } else if (countLevel?.length > 0) {
        countArr = countLevel?.filter((c) => c.exam_level === k);
      }

      console.log(countArr);
      return {
        exam_level: k,
        count_level: counts[k],
        right_weight: countArr.length > 0 ? countArr?.[0].right_weight : 0,
        wrong_weight: countArr.length > 0 ? countArr?.[0].wrong_weight : 0,
        no_answer_weight:
          countArr.length > 0 ? countArr?.[0].no_answer_weight : 0,
      };
    });

    setCountLevel(countsExtended);
    // }
  };

  useEffect(() => {
    if (dataRandomExams?.length > 0) {
      console.log("dataRandomExams", dataRandomExams);
      let newArr = [...dataRandomExams];

      let dataRandomExamArr = randomExams?.filter(
        (it) => !newArr.map((r) => r.id).includes(it?.id)
      );

      dataRandomExamArr.forEach((element) => {
        setDataRandomExams((prev) => [...prev, element]);
        setDataRandomExamsTime((prev) => [...prev, element]);
      });
    } else {
      setDataRandomExams(randomExams);
      setDataRandomExamsTime(randomExams);
    }
  }, [randomExams]);

  useEffect(() => {
    console.log("useState", dataRandomExams);
    if (dataRandomExams?.length > 0) {
      const rows = dataRandomExams?.slice(
        data.page * data.pageSize,
        data.page * data.pageSize + data.pageSize
      );

      setTimeout(() => {
        updateData("rows", rows);
        console.log(data);
      }, 500);

      let newArr = dataRandomExams;

      countQuestionLevel(newArr);

      const sumTime = newArr
        .map((it) => it?.question_time)
        .reduce((partialSum, a) => partialSum + a, 0);

      if (+summaryExamTime > 0 && sumTime !== +summaryExamTime) {
        let avg = summaryExamTime / dataRandomExams?.length;

        const isFloat = avg % 1 !== 0;

        newArr = newArr.map((item) => ({
          ...item,
          question_time: Math.floor(avg),
        }));

        if (isFloat) newArr[0].question_time += 1;
      }

      setDataRandomExamsTime(newArr);
      console.log(dataRandomExamsTime);
    }
  }, [dataRandomExams]);

  const handleSetExamMode = (value) => {
    setExamMode(value);
  };

  const handleOpenPopupRandomADT = () => {
    setIsOpenRandomADTPopup(true);
  };

  const handleClosePopupRandomADT = () => {
    setIsOpenRandomADTPopup(false);
  };

  const handleOpenPopupRandomShuffleOrNormal = () => {
    setIsOpenRandomShuffleOrNormalPopup(true);
  };

  const handleClosePopupRandomShuffleOrNormal = () => {
    setIsOpenRandomShuffleOrNormalPopup(false);
  };

  const handleOpenPopupAddQuestion = () => {
    setIsOpenPopupAddQuestion(true);
  };

  const handleClosePopupAddQuestion = () => {
    setIsOpenPopupAddQuestion(false);
  };

  const handleOpenPopupOrderingExamSet = () => {
    setIsOpenPopupOrderingExamSet(true);
  };

  const handleClosePopupOrderingExamSet = () => {
    setIsOpenPopupOrderingExamSet(false);
  };

  const handleOpenPopupClientLibrary = () => {
    setIsOpenPopupClientLibrary(true);
  };

  const handleClosePopupClientLibrary = () => {
    setIsOpenPopupClientLibrary(false);
  };

  const handleSummaryExamTimeChange = (e) => {
    const { value } = e.target;
    setSummaryExamTime(value);
  };

  useEffect(() => {
    if (dataRandomExams?.length > 0) {
      let newArr = [...dataRandomExams];
      const sumTime = newArr
        .map((it) => it.question_time)
        .reduce((partialSum, a) => partialSum + a, 0);

      if (+summaryExamTime > 0 && sumTime !== +summaryExamTime) {
        let avg = summaryExamTime / dataRandomExams?.length;

        const isFloat = avg % 1 !== 0;

        newArr = newArr.map((item) => ({
          ...item,
          question_time: Math.floor(avg),
        }));

        if (isFloat) newArr[0].question_time += 1;
      }
      setDataRandomExams(newArr);
    }
  }, [summaryExamTime]);

  const handleLevelChange = (value) => {
    setLevel(value);
  };

  const handleSubject = (event, value) => {
    setSubjectId(value?.id);
    setDataRandomExams([]);
    setDataRandomExamsTime([]);
    updateData("rows", []);
    setCountLevel([]);
  };

  const updateResultMappingChanged = (index) => (e) => {
    const newArr = resultMapping.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });

    setResultMapping(newArr);
  };

  const updateCountLevelChanged = (index) => (e) => {
    const newArr = countLevel.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });

    setCountLevel(newArr);
  };

  useEffect(() => {
    if (type === "edit") {
      dispatch(getExamSetById(id));
    } else {
      dispatch(clearStateExamSet());
    }
  }, [type]);

  useEffect(() => {
    if (examSetDetail !== undefined && type === "edit") {
      console.log("examSetDetail?.subject_id", examSetDetail?.subject_id);

      setName(examSetDetail?.name);
      setExamMode(examSetDetail?.exam_mode);
      setAuthor(examSetDetail?.author);
      setSubjectId(examSetDetail?.subject_id);
      setSubjectName(
        dataSubject?.find((subject) => subject.id == examSetDetail?.subject_id)
          ?.label
      );
      setAmountQuestion(examSetDetail?.amount_question);
      setLevel(examSetDetail?.level);
      setDescription(examSetDetail?.description);
      setResultMapping(examSetDetail?.examSetResultMapping);
      setSummaryExamTime(examSetDetail?.exam_time);
      setDataRandomExams(
        examSetDetail?.examSetQuestion
          ?.filter((x) => x?.detail != undefined)
          .map((it) => {
            return {
              code: it?.detail?.code,
              createdAt: it?.updatedAt,
              examOwner: it?.detail?.exam_owner,
              id: it?.detail?.id,
              // jobPositionId: 3656,
              level: it?.detail?.center_level,
              mylevel: it?.detail?.myLevel,
              name: it?.detail?.name,
              nameUpper: it?.detail?.name_upper,
              question: it?.detail?.choice_description,
              question_time: it?.question_time,
              status: it?.detail?.status,
              subjectId: it?.detail?.subject_id,
              topicName: it?.detail?.topic_name,
            };
          })
      );
      setDataRandomExamsTime(examSetDetail?.examSetQuestion);
      console.log("after edit", dataRandomExams);
    }

    return () => {};
  }, [examSetDetail]);

  const submitCreateExamSet = () => {
    if (validate()) {
      let obj = {
        name: name,
        subject_id: subjectId,
        amount_question: +amountQuestion,
        author: author,
        exam_mode: examMode,
        exam_time: +summaryExamTime,
        company_id: companyId,
        description: description,
        language: language,
        level: +level,
        result_mapping: resultMapping,
        question: dataRandomExamsTime?.map((it) => {
          return {
            exam_question_id: it.id,
            level: it.mylevel || it.level,
            question_time: +it.question_time,
          };
        }),
        summary: countLevel?.map((it) => {
          return {
            exam_level: +it.exam_level,
            right_weight: +it.right_weight,
            wrong_weight: +it.wrong_weight,
            no_answer_weight: +it.no_answer_weight,
          };
        }),
        client: [
          {
            company_id: 1,
          },
        ],
      };
      dispatch(createExamSet(obj)).then(() =>
        history.push("/library/examseries/all")
      );
    }
  };

  const submitEditExamSet = () => {
    if (validate()) {
      let obj = {
        name: name,
        subject_id: subjectId,
        amount_question: +amountQuestion,
        author: author,
        exam_mode: examMode,
        exam_time: +summaryExamTime,
        company_id: +companyId,
        description: description,
        language: language,
        level: +level,
        result_mapping: resultMapping,
        question: dataRandomExamsTime?.map((it) => {
          return {
            exam_question_id: it.id,
            level: it.mylevel || it.level,
            question_time: +it.question_time,
          };
        }),
        summary: countLevel?.map((it) => {
          return {
            exam_level: +it.exam_level,
            right_weight: +it.right_weight,
            wrong_weight: +it.wrong_weight,
            no_answer_weight: +it.no_answer_weight,
          };
        }),
        client: [
          {
            company_id: 1,
          },
        ],
      };

      dispatch(updateExamSet(obj, id)).then(() =>
        history.push("/library/examseries/all")
      );
    }
  };

  const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));

  useEffect(() => {
    setTimeout(() => {
      const rows = dataRandomExams?.slice(
        data.page * data.pageSize,
        data.page * data.pageSize + data.pageSize
      );

      updateData("rowCount", dataRandomExams?.length);

      updateData("rows", rows);
    }, 500);
  }, [data.page, data.pageSize]);

  const handlePageChange = (nextPage) => {
    updateData("page", nextPage);
  };

  const handleDeleteExam = (data) => {
    let updateExam = dataRandomExams?.filter((it) => it.id !== data?.id);
    setDataRandomExams(updateExam);
    setDataRandomExamsTime(updateExam);
  };

  return (
    <SidebarLayout title="ชุดข้อสอบ">
      <Div>
        {examMode === "adt"
          ? Boolean(isOpenRandomADTPopup) && (
              <PopupRandomExamADT
                open
                onClose={() => handleClosePopupRandomADT()}
                subject={subjectId}
              />
            )
          : Boolean(isOpenRandomShuffleOrNormalPopup) && (
              <PopupRandomExamShuffleOrNormal
                open
                onClose={() => handleClosePopupRandomShuffleOrNormal()}
                subject={subjectId}
                randomType={examMode}
              />
            )}
        {Boolean(isOpenPopupAddQuestion) && (
          <PopupAddQuestion
            subject={subjectId}
            open
            onClose={() => handleClosePopupAddQuestion()}
            setDataRandomExams={setDataRandomExams}
            dataRandomExams={dataRandomExams}
          />
        )}
        {Boolean(isOpenPopupOrderingExamSet) && (
          <PopupOrderingExamSet
            open
            onClose={() => handleClosePopupOrderingExamSet()}
            dataRandomExams={dataRandomExamsTime}
            setDataRandomExams={setDataRandomExams}
            setDataRandomExamsTime={setDataRandomExamsTime}
            setSummaryExamTime={setSummaryExamTime}
            summaryExamTime={summaryExamTime}
          />
        )}
        {Boolean(isOpenPopupClientLibrary) && (
          <PopupClientLibrary
            open
            onClose={() => handleClosePopupClientLibrary()}
            setClientLibrary={setClientLibrary}
          />
        )}
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="header-title">
              <div className="title">
                <BackIcon
                  className="btn_back"
                  onClick={() => history.goBack()}
                />
                {type === "edit" ? "แก้ไขชุดข้อสอบ" : "สร้างชุดข้อสอบ"}
              </div>
              {type === "edit" ? (
                <ButtonBlack onClick={() => submitEditExamSet()}>
                  แก้ไขชุดข้อสอบ
                </ButtonBlack>
              ) : (
                <ButtonBlack
                  id="btn-submit"
                  onClick={() => submitCreateExamSet()}
                >
                  สร้างชุดข้อสอบ
                </ButtonBlack>
              )}
            </div>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <h2>กรอกรายละเอียด</h2>
                <div className="form-wrapper">
                  <div className="section-group">
                    <div className="group-field">
                      <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="ชื่อชุดข้อสอบ"
                        id="field-name"
                        error={errors.name}
                      />

                      <TextField
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        label="Author"
                        id="field-author"
                        error={errors.author}
                      />
                    </div>
                    <div
                      className="group-field-subject"
                      style={{ width: "500px", margin: "15px 0" }}
                    >
                      {/* <Select
                        label="วิชา"
                        value={subjectId}
                        items={dataSubject}
                        defaultValue="หมวดวิชา"
                        onChange={(e) => handleSubject(e.target.value)}
                        errorText={errors.subject}
                        isError={errors.subject}
                        id="field-subject"
                      /> */}
                      <Autocomplete
                        disablePortal
                        value={subjectName}
                        options={dataSubject}
                        sx={{ width: 300 }}
                        onChange={handleSubject}
                        id="field-subject"
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.id}>
                            <span>{option.label}</span>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="วิชา" />
                        )}
                      />
                    </div>
                    <div className="group-field">
                      <TextField
                        value={amountQuestion}
                        onChange={(e) => setAmountQuestion(e.target.value)}
                        label="จำนวนข้อ"
                        name="search_examer"
                        error={errors.amountQuestion}
                        id="field-amountQuestion"
                      />
                      <Select
                        label="Level"
                        items={difficultyLevelList}
                        value={level}
                        defaultValue="Level"
                        onChange={(e) => handleLevelChange(e.target.value)}
                        errorText={errors.level}
                        isError={errors.level}
                        id="field-level"
                      />
                    </div>
                    <div className="score-container">
                      <Grid container spacing={1}>
                        <Grid item xs={10}>
                          <TextFieldArea
                            value={description}
                            placeholder="รายละเอียดข้อสอบ"
                            onChange={(e) => setDescription(e.target.value)}
                            errorText={errors.description}
                            isError={errors.description}
                            id="field-description"
                          />
                        </Grid>
                      </Grid>
                    </div>
                    {resultMapping?.map((item, index) => (
                      <div className="score-container" key={index}>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <TextField value={item?.name} disabled />
                          </Grid>
                          <Grid item xs={4}>
                            <Grid
                              spacing={1}
                              container
                              direction="row"
                              alignItems="center"
                            >
                              <Grid container item xs={4}>
                                <TextField
                                  name={"min_score"}
                                  value={item?.min_score}
                                  onChange={updateResultMappingChanged(index)}
                                  error={
                                    errors.resultmapping?.[index]?.min_score
                                  }
                                  id={`field-min-score-${index}`}
                                />
                              </Grid>
                              <Grid container item xs={4}>
                                <div
                                  style={{ textAlign: "center", width: "100%" }}
                                >
                                  {" "}
                                  To
                                </div>
                              </Grid>
                              <Grid container item xs={4}>
                                <TextField
                                  name={"max_score"}
                                  value={item?.max_score}
                                  onChange={updateResultMappingChanged(index)}
                                  error={
                                    errors.resultmapping?.[index]?.max_score
                                  }
                                  id={`field-max-score-${index}`}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={10}>
                            <TextFieldArea
                              name={"description"}
                              value={item?.description}
                              onChange={updateResultMappingChanged(index)}
                              errorText={
                                errors.resultmapping?.[index]?.description
                              }
                              isError={
                                errors.resultmapping?.[index]?.description
                              }
                              id={`field-description-${index}`}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </div>
                  <div className="form-wrapper">
                    <h2>Exam Mode</h2>
                    <div className="section-group">
                      <div className="group-field">
                        <Select
                          value={examMode}
                          items={modList}
                          onChange={(e) => handleSetExamMode(e.target.value)}
                          id="field-exam-mod"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: "4px",
                  marginTop: "20px",
                  padding: "10px 20px",
                }}
              >
                <Grid item xs={12}>
                  <h2 style={{ margin: "0 0 25px 0" }}>Summary</h2>
                  <InlineSpaceText label="เวลารวมในการทำข้อสอบทั้งหมด">
                    <TextField
                      value={summaryExamTime}
                      type="number"
                      onChange={(e) => handleSummaryExamTimeChange(e)}
                      error={errors.summaryExamTime}
                      id="field-summary-exam-time"
                    />
                  </InlineSpaceText>
                  <p>จำนวนข้อสอบทั้งหมด : {dataRandomExams?.length} ข้อ</p>
                  {countLevel?.map((item, index) => (
                    <div className="summary-score-container" key={index}>
                      <div className="summary-score-title">
                        จำนวนข้อสอบ Level {item?.exam_level} :{" "}
                        {item?.count_level} ข้อ
                      </div>
                      <div className="summary-score-box">
                        <div className="header"> ตอบถูก Then </div>
                        <div className="score">
                          <Select
                            name={"right_weight"}
                            items={scoreList}
                            value={item?.right_weight}
                            defaultValue="Score"
                            className="field-score"
                            onChange={updateCountLevelChanged(index)}
                            id={`field-right-weight-${index}`}
                          />
                        </div>
                      </div>

                      <div className="summary-score-box">
                        <div className="header"> ตอบผิด Then </div>
                        <div className="score">
                          <Select
                            name={"wrong_weight"}
                            items={scoreList}
                            value={item?.wrong_weight}
                            defaultValue="Score"
                            className="field-score"
                            onChange={updateCountLevelChanged(index)}
                            id={`field-wrong-weight-${index}`}
                          />
                        </div>
                      </div>
                      <div className="summary-score-box">
                        <div className="header"> ไม่ตอบ Then </div>
                        <div className="score">
                          <Select
                            name={"no_answer_weight"}
                            items={scoreList}
                            value={item?.no_answer_weight}
                            defaultValue="Score"
                            className="field-score"
                            onChange={updateCountLevelChanged(index)}
                            id={`field-no-answer-weight-${index}`}
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                  <p>
                    <strong>
                      *Remark : ข้อที่ถูกกำหนดเวลาในการทำ
                      ผู้สอบจะไม่สามารถข้ามข้อสอบนั้น ๆ ได้
                    </strong>
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <div className="table-wrapper">
                    <div className="form-wrapper">
                      <div className="section-group">
                        <div className="group-field">
                          <TextField
                            value={topicName}
                            onChange={(e) => setTopicName(e.target.value)}
                            label="ชื่อบริษัท"
                            id={`field-topic-name`}
                          />
                          <TextField
                            value={topicName}
                            onChange={(e) => setTopicName(e.target.value)}
                            label="Email"
                            id={`field-email`}
                          />
                          <TextField
                            value={topicName}
                            onChange={(e) => setTopicName(e.target.value)}
                            label="เบอร์โทร"
                            id={`field-phone`}
                          />
                        </div>
                      </div>
                      <div className="section-group">
                        <div className="group-field end">
                          <Button
                            onClick={() => handleOpenPopupClientLibrary()}
                          >
                            Select Client Library
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Table
                      columns={columnsClient}
                      rows={clientLibrary}
                      // rowCount={clientLibrary?.length}
                      // pageSize={10}
                      // page={page}
                      disableSelectionOnClick={true}
                      checkboxSelection={false}
                      // onPageChange={handlePageChange}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="table-wrapper">
                  <div className="button-table">
                    <div className="button-table-item">
                      <Button
                        className="btn"
                        onClick={(e) => handleOpenPopupAddQuestion()}
                      >
                        เพิ่มข้อสอบเข้าชุดข้อสอบ
                      </Button>
                      <Button
                        className="btn"
                        onClick={() => {
                          examMode === "adt"
                            ? handleOpenPopupRandomADT()
                            : handleOpenPopupRandomShuffleOrNormal();
                        }}
                      >
                        สุ่มข้อสอบเข้าชุดข้อสอบจาก Center Library
                      </Button>
                    </div>
                    <div className="button-table-item">
                      <Button onClick={() => handleOpenPopupOrderingExamSet()}>
                        Ordering
                      </Button>
                    </div>
                  </div>
                  <div className="table-wrapper">
                    <Table
                      columns={columns}
                      rows={data?.rows}
                      rowCount={dataRandomExams?.length}
                      disableSelectionOnClick={true}
                      checkboxSelection={false}
                      onPageChange={handlePageChange}
                      onDeleteRow={handleDeleteExam}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <br />
      </Div>
    </SidebarLayout>
  );
};

export default ExamSeriesCreate;
